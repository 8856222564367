// @flow
import format from "date-fns/format";
import * as React from 'react';
import { StyleProp, View as DView, ViewStyle } from 'react-native';
import { S } from "../constants/Styles";
import { userStore } from "../stores/userStore/UserStore";
import { Text } from "./Themed";
import ImagePicker from "./UserProfile/ImagePicker";

export function UserCard({
                             horizontal = false,
                             imgSize = 100,
                             textContainerStyle
                         }: { horizontal?: boolean, imgSize?: number, textContainerStyle?: StyleProp<ViewStyle> }) {
    const orientation = horizontal ? S.row : S.column;
    return (
        <DView style={[orientation, S.mb2, S.center]}>
            <DView style={horizontal ? S.mr2 : S.mb2}>
                <ImagePicker size={imgSize}/>
            </DView>
            <DView style={[textContainerStyle]}>
                <Text variant="H3">Hello, {userStore.name}</Text>
                <Text variant="P2">{format(new Date(), "cccc, MMM dd, yyyy")}</Text>
            </DView>
        </DView>
    );
}
