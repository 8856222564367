import { useQuery } from "@tanstack/react-query";
import { getAllClassesPhotos } from "../../api/client";
import { IClassPhoto } from "../../api/types";
import { queryClient } from "../QueryProvider";

export const useClassesPhotos = () => {
    const invalidate = () => queryClient.invalidateQueries(['allClassesPhotos'])
    const query = useQuery({
        queryKey: ["allClassesPhotos"],
        queryFn: () => getAllClassesPhotos(),
        networkMode: 'offlineFirst'
        // suspense: true,
    });


    let data = [] as IClassPhoto[];
    if (!query.isLoading && query?.data) data = query?.data;

    return {
        data,
        invalidate,
        loading: query?.isLoading,
    };
};
