import {ActivityIndicator, Pressable, View as DView} from 'react-native';
import {View} from "../Themed";
import {S} from "../../constants/Styles";
import {ISubscription} from "../../api/types";
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js';
import {UseStripe} from "../../hooks/queries/useStripe";
import {Elements} from '@stripe/react-stripe-js';
import {SubscriptionSummary} from "./SubscriptionSummary";
import {useColors} from "../../hooks/ThemeContext";
import PaymentCheckoutForm from "./PaymentCheckoutForm";

const stripePromise = loadStripe('pk_test_51MReb3Lj12GqMlr91UJx5soeWhZmWuv8LnVoK1nXNeyb8mTu23NHkEbrcSE9U6nJbo4M5kPs55MnXvxkWboxvaMN00I3F3DlHF');

export function PaymentsBase({
                                 subscription,
                                 resetPayments
                             }: { subscription: ISubscription, resetPayments: () => void }) {
    const colors = useColors();
    if (!subscription) return null;

    const subscriptionId = subscription.id;

    const {stripeClientSecret, loading} = UseStripe(subscriptionId);

    const clientSecret = stripeClientSecret;
    const options: StripeElementsOptions = {
        // passing the client secret obtained from the server
        clientSecret,
        appearance: {
            theme: 'stripe',
            labels: 'floating',
            variables: {
                colorText: colors.accent,
            }
        }
    };

    if (loading && !stripeClientSecret) {
        return <View style={S.flex}>
            <ActivityIndicator color={colors.accent} size={'large'}/>
        </View>
    }

    return (
        <View style={[S.flex, S.ph2, S.pv2, S.radius2]} bg={"white"}>
            <Pressable>
                <DView style={[S.mb3]}>
                    <SubscriptionSummary subscription={subscription} invert/>
                </DView>
                <Elements stripe={stripePromise} options={options} key={options.clientSecret}>
                    <PaymentCheckoutForm clientSecret={stripeClientSecret}
                                         amount={`${subscription.price} ${subscription.priceUnit}`}
                                         resetPayments={resetPayments}/>
                </Elements>
            </Pressable>
        </View>
    );
}
