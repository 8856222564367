// @flow
import * as React from 'react';
import {Text} from "../../Themed";
import HomeCard from "../HomeCard";
import addDays from "date-fns/addDays";
import parse from "date-fns/parse";
import {dateTimeFormat} from "../../../helpers/helpers";
import {IUserSubscription} from "../../../api/types";
import {useUserAbsent} from "../../../hooks/queries/useUserAbsent";
import {StyleProp, ViewStyle} from 'react-native';

type Props = {
    subscriptions: IUserSubscription[],
    contentContainerStyle?: StyleProp<ViewStyle>
};

export function DaysAttended(props: Props) {

    const {data} = useUserAbsent();
    const currentDate = new Date();

    return (
        <>
            {props.subscriptions.map(sub => {

                const count = countDays(parse(sub.subscription.dateFrom + " " + sub.subscription.timeFrom, dateTimeFormat, new Date()), currentDate, sub.subscription.days);
                const totalCount = count - (data?.filter(days => String(days.dailySubscription.subscriptionId) === String(sub.subscription.id))?.length || 0);

                return (
                    <HomeCard key={sub.id}
                              iconProps={{name: "calendar-outline", color: 'accent'}}
                              top={<Text variant="P2">Days Attended</Text>}
                              subText={<Text variant={'P1'}>{sub.subscription.class.name}</Text>}
                              bottom={<Text
                                  variant="H2">{`${totalCount} ${totalCount === 1 ? 'Day' : 'Days'}`}</Text>}
                              contentContainerStyle={[props?.contentContainerStyle]}/>
                )
            })}
        </>

    );
}

function countDays(startDate: Date, endDate: Date, days: number[]): number {
    let currentDate = startDate;
    let count = 0;

    while (currentDate <= endDate) {
        if (days.includes(currentDate.getDay())) {
            count++;
        }

        currentDate = addDays(currentDate, 1);
    }

    return count;
}
