// @flow
import { startOfToday, startOfTomorrow } from "date-fns";
import format from "date-fns/format";
import parse from "date-fns/parse";
import * as React from 'react';
import { useWindowDimensions, View as DView } from 'react-native';
import { S } from "../../../constants/Styles";
import { currentTime, dateFormat, timeFormat } from "../../../helpers/helpers";
import { useDailySubs } from "../../../hooks/queries/useDailySubs";
import { useUserSubscriptions } from "../../../hooks/queries/useUserSubscriptions";
import { Text } from "../../Themed";
import HomeCard from "../HomeCard";
import { DaysAttended } from "./DaysAttended.web";

export function User() {
    const { width } = useWindowDimensions();

    const { activeSubscriptions, upcomingSubscriptions } = useUserSubscriptions();

    let grid = { gridTemplateColumns: 'repeat(4, 1fr)' };
    if (width < 1200 && width >= 768) {
        grid = { gridTemplateColumns: 'repeat(2, 1fr)' };
    } else if (width < 768) {
        grid = { gridTemplateColumns: 'repeat(1, 1fr)' }
    }

    const dailies = useDailySubs(activeSubscriptions.map(sub => sub.subscription.id).join('-'), !!activeSubscriptions.length);

    if (!dailies.data) return null;
    const today = dailies.data.filter(daily => daily.date === format(startOfToday().getTime(), dateFormat));
    const tomorrow = dailies.data.filter(daily => daily.date === format(startOfTomorrow().getTime(), dateFormat));

    const subToday = today.map(daily => {
        const sub = activeSubscriptions.find(sub => sub.subscription.id + "" === daily.subscriptionId + "" && parse(sub.subscription.timeFrom, timeFormat, new Date()) >= parse(currentTime(), timeFormat, new Date()));
        
        if (!sub) return null;
        return <HomeCard
            iconProps={{ name: "alarm-outline", color: 'accent' }}
            top={<Text variant="P2" style={[S.flex]}>{sub.subscription.class.name}</Text>}
            bottom={<Text variant="H2">Class Today at {sub?.subscription.timeFrom}</Text>}
            subText={<Text variant={'P1'}>{sub?.subscription.instructor.name}</Text>}
            key={sub.id}
        />
    }).filter(Boolean);


    const subTomorrow = tomorrow.map(daily => {
        const sub = activeSubscriptions.find(sub => sub.subscription.id + "" === daily.subscriptionId + "");
        if (!sub) return null;
        return <HomeCard
            iconProps={{ name: "alarm-outline", color: 'accent' }}
            top={<Text variant="P2" style={[S.flex]}>{sub.subscription.class.name}</Text>}
            bottom={<Text variant="H2">Class Tomorrow at {sub?.subscription.timeFrom}</Text>}
            subText={<Text variant={'P1'}>{sub?.subscription.instructor.name}</Text>}
            key={sub.id}
        />
    }).filter(Boolean);


    return (
        <DView style={[{ padding: 1 }]}>
            <div style={{ display: 'grid', gap: 16, ...grid }}>
                {subToday}
                {subTomorrow}

                {activeSubscriptions?.length ?
                    <DaysAttended subscriptions={activeSubscriptions} />
                    : null}
                <HomeCard
                    iconProps={{ name: "body-outline", color: 'accent' }}
                    top={<Text variant="P2">Number of Active Subscriptions</Text>}
                    bottom={<Text variant="H2">{`${activeSubscriptions?.length} Subscriptions`}</Text>} />

                <HomeCard
                    iconProps={{ name: "time-outline", color: 'accent' }}
                    top={<Text variant="P2">Number of Upcoming Subscriptions</Text>}
                    bottom={<Text variant="H2">{`${upcomingSubscriptions?.length} Subscriptions`}</Text>}
                />
            </div>
        </DView>
    );
}
