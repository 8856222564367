import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { observer } from 'mobx-react';
import { memo } from "react";
import { useWindowDimensions } from "react-native";
import { View } from "../components/Themed";
import { S } from '../constants/Styles';
import { useColors } from "../hooks/ThemeContext";
import ForgotPasswordScreen from "../screens/Auth/ForgotPassword/ForgotPasswordScreen";
import MainScreen from "../screens/Auth/Main/MainScreen";
import { ResetPasswordScreen } from "../screens/Auth/ResetPassword/ResetPasswordScreen";
import SignInScreen from "../screens/Auth/SignIn/SignInScreen";
import SignUpScreen from "../screens/Auth/SignUp/SignUpScreen";
import { userStore } from "../stores/userStore/UserStore";
import { AuthStackParamList, RootStackParamList } from "../types";
import TabBar from "./CurvedBar";
import { navigationRef } from "./helpers";
import LinkingConfiguration from "./LinkingConfiguration";

const C = observer(() => {
    const {accessToken} = userStore;
    return (<>
            {accessToken ?
                <RootNavigator/> :
                <AuthNavigator/>}
        </>
    )
})

const navTheme = {
    ...DefaultTheme,
    colors: {
        ...DefaultTheme.colors,
        background: 'transparent',
    },
};

const Navigation = () => {
    return (
        <NavigationContainer ref={navigationRef} linking={LinkingConfiguration} theme={navTheme} documentTitle={{
            formatter: (options, route) =>
                `${options?.title ?? route?.name} - BeFit Ladies Gym`,
        }}>
            <C/>
        </NavigationContainer>
    );
}

export default Navigation;

const Stack = createNativeStackNavigator<RootStackParamList>();

const RootNavigator = memo(() => {
    const colors = useColors();
    const {width} = useWindowDimensions();

    return (
        <Stack.Navigator
            screenOptions={{
                // headerStyle: {backgroundColor: colors.background2},
                headerTintColor: colors.accent,
                headerShown: false,
                contentStyle: width <1200 ? {paddingHorizontal: 0, paddingVertical: 10} : {
                    paddingHorizontal: 40,
                    paddingBottom: 10
                }
            }}
            initialRouteName='Root'
        >
            <Stack.Group screenOptions={{animation: 'slide_from_right'}}>
                <Stack.Screen name='Root' component={TabBar} options={{headerShown: false}}/>
            </Stack.Group>
        </Stack.Navigator>
    );
});

const AuthStack = createNativeStackNavigator<AuthStackParamList>();
//
const AuthNavigator = memo(() => {
    const colors = useColors();
    return (
        <View style={S.flex}>
            <AuthStack.Navigator
                screenOptions={{
                    headerStyle: {backgroundColor: colors.background},
                    headerTintColor: colors.accent,
                    headerShown: false,
                    animation: 'slide_from_right',
                }}
                initialRouteName='MainScreen'
            >
                <AuthStack.Screen options={{title: 'Main'}} name='MainScreen' component={MainScreen}/>
                <AuthStack.Screen options={{title: 'Sign In'}} name='SignInScreen' component={SignInScreen}/>
                <AuthStack.Screen options={{title: 'Sign Up'}} name='SignUpScreen' component={SignUpScreen}/>
                <AuthStack.Screen options={{title: 'Forgot Password'}} name='ForgotPasswordScreen'
                                  component={ForgotPasswordScreen}/>
                <AuthStack.Screen options={{title: 'Reset Password'}} name='ResetPasswordScreen'
                                  component={ResetPasswordScreen}/>
            </AuthStack.Navigator>
        </View>

    );
});

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
// const BottomTab = createBottomTabNavigator<RootTabParamList>();

// const BottomTabNavigator = memo(() => {
//     const colors = useColors();
//
//     return (
//         <BottomTab.Navigator
//             initialRouteName='Home'
//             screenOptions={{
//                 headerShown: false,
//                 tabBarActiveTintColor: colors.accent,
//                 tabBarInactiveTintColor: colors.xColor,
//                 headerStyle: {backgroundColor: colors.background, elevation: 0},
//                 headerTitleStyle: {fontSize: 25},
//                 headerTintColor: colors.accent,
//                 tabBarStyle: {
//                     borderTopWidth: 0,
//                     backgroundColor: colors.background,
//                     height: 60,
//                     paddingBottom: 10,
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     elevation: 0
//                 },
//                 tabBarItemStyle: {},
//                 tabBarLabel: () => null,
//                 tabBarIconStyle: {fontSize: 50},
//             }}
//         >
//             <BottomTab.Screen
//                 name='Home'
//                 component={HomeScreen}
//                 options={{
//                     title: 'Home',
//                     tabBarIcon: ({color}) => <Icon name='home-outline' color={color}/>,
//                 }}
//             />
//             <BottomTab.Screen
//                 name='Classes'
//                 component={Classes}
//                 options={{
//                     title: 'Classes',
//                     tabBarIcon: ({color}) => (
//                         <View
//                             style={[
//                                 {
//                                     height: 60,
//                                     width: 60,
//                                     borderRadius: 60,
//                                     backgroundColor: colors.background,
//                                     paddingTop: 5,
//                                     paddingBottom: 5,
//                                     marginBottom: 40,
//                                 },
//                                 S.center,
//                                 S.justify,
//                             ]}
//                         >
//                             <View style={[S.flex, {width: 50}, S.rounded50, S.justify, S.center, {paddingLeft: 2}]}
//                                   bg='accent'>
//                                 <Icon name='book-outline' color={colors.background} size={35}/>
//                             </View>
//                         </View>
//                     ),
//                 }}
//             />
//             <BottomTab.Screen
//                 name='Profile'
//                 component={ProfileScreen}
//                 options={{
//                     title: 'Profile',
//                     tabBarIcon: ({color}) => <Icon name='person-outline' color={color}/>,
//                 }}
//             />
//         </BottomTab.Navigator>
//     );
// });
