import React from "react";
import {Button, View} from "../../../components/Themed";
import {S} from "../../../constants/Styles";
import {useColors} from "../../../hooks/ThemeContext";
import {navigate} from "../../../navigation/helpers";
import {ActivityIndicator, Image, View as DView} from 'react-native';
// @ts-ignore
import img from '../../../../assets/images/main.jpg';
import {Logo} from "../../../components/Icons/Icons";
import {observer} from "mobx-react";
import {userStore} from "../../../stores/userStore/UserStore";

const MainScreen = observer(() => {
    const colors = useColors();
    const {loading} = userStore;
    return (
        <View style={[S.flex, S.end]}>
            <Image style={[S.wFull, S.hFull, {position: 'absolute', top: 0, right: 0, left: 0}]}
                   source={{uri: img}}/>
            <DView style={[S.p2, S.flex, S.between]}>
                <DView style={[S.flex2, S.end, S.center]}>
                    <Logo size={350}/>
                </DView>
                <DView style={[S.flex, S.end]}>
                    {loading ? <ActivityIndicator color={"white"} size={'large'}/> : (
                        <>
                            <Button title="Start workout" onPress={() => navigate("SignInScreen")}
                                    titleStyle={[S.flex, S.H1, {color: colors.white}]}
                                    buttonStyle={{backgroundColor: "transparent"}}></Button>
                            <Button title="Join us" onPress={() => navigate("SignUpScreen")}
                                    titleStyle={[S.flex, S.H2, {color: colors.white}]}
                                    buttonStyle={{backgroundColor: "transparent"}}></Button>
                        </>
                    )
                    }
                </DView>
            </DView>
        </View>
    );
});

export default MainScreen;
