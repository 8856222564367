import { Ionicons } from "@expo/vector-icons";
import { Icon as RneuiIcon, IconProps as DIconProps } from "@rneui/base";
import React, { useMemo } from "react";
import { ColorType, useColors } from "../hooks/ThemeContext";

export type IconProps =
    Omit<DIconProps, "type" | "name" | "color">
    & { name: React.ComponentProps<typeof Ionicons>["name"], color?: ColorType }
const Icon = (props: IconProps) => {
    const colors = useColors();
    const color = useMemo(() => props?.color ? colors[props.color] : undefined, [])
    return <RneuiIcon type="ionicon" {...props} name={props.name} color={color}/>;
};

export default Icon;
