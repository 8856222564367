import React from "react";
import {Text} from "./Themed";
import {S} from '../constants/Styles';
import {View as DView} from 'react-native';

const Empty = ({title}: { title: string }) => {
    return (
        <DView style={[S.flex, S.center, S.justify]}>
            <Text variant={"H1"} style={S.textCenter}>{title}</Text>
        </DView>
    );
}

export default Empty;
