// @flow
import { Divider } from "@rneui/themed";
import format from "date-fns/format";
import * as React from 'react';
import { ActivityIndicator, FlatList, View as DView } from "react-native";
import { setUserAbsent } from "../../api/client";
import { IUserInfo } from "../../api/types";
import { Button, Text } from "../../components/Themed";
import { dayArray } from "../../constants/Constants";
import { S } from '../../constants/Styles';
import { dateFormat, formatUIDate, showToast } from "../../helpers/helpers";
import { useAbsent } from "../../hooks/queries/useAbsent";
import { useInstructorSubscriptions } from "../../hooks/queries/useInstructorSubscriptions";
import { useColors } from "../../hooks/ThemeContext";
import { RootStackScreenProps } from "../../types";

export const InstructorClass = ({route}: RootStackScreenProps<'InstructorClass'>) => {
    const subscriptionId = route.params?.subscriptionId;
    const {data, invalidate: invalidateAbsents, loading: absentLoading} = useAbsent(subscriptionId);
    const {loading: subsLoading, invalidate: invalidateSubs, activeSubscriptions} = useInstructorSubscriptions();
    const colors = useColors();

    if (!subscriptionId) return null

    const absentUserIds = data.map(sub => sub.dailySubscription.subscriptionId);

    if (subsLoading || absentLoading) return <ActivityIndicator color={colors.accent} size={'large'}/>;

    const subscription = activeSubscriptions?.find((sub) => String(sub.id) == String(subscriptionId));

    if (!subscription) return null;

    const setAbsent = (sub: { subscriptionId: string, user: IUserInfo }) =>
        setUserAbsent({
            date: format(new Date(), dateFormat),
            subscriptionId: sub.subscriptionId,
            userId: sub.user.id
        }).then(invalidateAbsents).catch((e) => {
            showToast(e?.response?.data?.message || 'Failed!')
            return;
        })

    return (
        <DView style={[S.flex, S.pt1]}>
            <DView style={[S.p2, S.radiusBottom2]}>
                <Text variant={'Huge'} color='accent'>{subscription?.class?.name}</Text>
                <Text variant={'H2'}
                      color='accent'
                      style={S.mb1}
                >{subscription?.userSubscriptions.length} {subscription?.userSubscriptions?.length === 1 ? 'student' : 'students'}</Text>
                <Text variant={"Label"}
                      color={"accent"}>{formatUIDate(subscription.dateFrom)} - {formatUIDate(subscription.dateTo)}</Text>
                <Text variant={"Label"}
                      color={"accent"}>{subscription.days.map(day => dayArray[day]).join(" - ")}</Text>
            </DView>
            <DView style={[S.flex]}>
                <FlatList
                    onRefresh={() => {
                        invalidateAbsents();
                        invalidateSubs();
                    }}
                    refreshing={absentLoading || subsLoading}
                    contentContainerStyle={[S.flexGrow1]}
                    data={subscription?.userSubscriptions}
                    ItemSeparatorComponent={() => <Divider color={colors.lightGrey}
                                                           style={[S.selfCenter, {width: "80%", opacity: 0.3}]}/>}
                    renderItem={({item: subscription}) => {
                        const isAbsent = absentUserIds.includes(subscription.subscriptionId);
                        return <DView key={subscription.subscriptionId} style={[S.row, S.center, S.p2]}>
                            <Text color={"accent"}
                                  variant={"H2"} style={S.mr3}>{subscription?.user?.name}</Text>
                            {isAbsent ? <Text variant={"P1"} style={S.mr0} color={'darkGrey'}>Absent Today</Text> :
                                <Button title={'Set Absent'}
                                        buttonStyle={[S.flexShrink1, {borderColor: colors.deepOrange}]}
                                        titleStyle={[S.SubText, {color: colors.deepOrange}]}
                                        type={'outline'}
                                        onPress={() => setAbsent(subscription)}/>}
                        </DView>
                    }
                    }/>
            </DView>

        </DView>
    );
}
