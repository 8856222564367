import { useQuery } from "@tanstack/react-query";
import { getAbsent } from "../../api/client";
import { IUserAbsent } from "../../api/types";
import { queryClient } from "../QueryProvider";

export const useAbsent = (userSubscriptionId: string) => {

    const invalidate = () => queryClient.invalidateQueries(['absentUsers'])

    const query = useQuery({
        queryKey: ["absentUsers"],
        queryFn: () => getAbsent(userSubscriptionId),
        // suspense: true,
        enabled: !!userSubscriptionId
    });

    let data = [] as IUserAbsent[];
    if (!query.isLoading && query?.data) data = query?.data;

    return {
        data,
        invalidate,
        loading: query?.isLoading,
    };
};
