import { View as DView } from 'react-native';
import { ISubscription } from '../../api/types';
import { S } from '../../constants/Styles';
import { SubscriptionCard } from "../SubscriptionCard";

const Subscription = ({
    selectedDate,
    allAvailableDates,
    onPress,
    isSchedule
}: { selectedDate: string, allAvailableDates: ISubscription[], onPress?: (sub: ISubscription) => void , isSchedule?: boolean}) => {

    let classBookings = [];
    if (!isSchedule)
        classBookings = allAvailableDates.filter(date => selectedDate === date.dateFrom);
    else
        classBookings = allAvailableDates.filter(date => selectedDate >= date.dateFrom && selectedDate <= date.dateTo);
        
    return (
        <DView style={[S.flex, S.row, S.wrap]}>
            {
                classBookings.map((item, index) => (
                    <DView style={S.mr1} key={item.id}>
                        <SubscriptionCard sub={item}
                            variableColor={index % 2 ? 'accent' : 'accent2'}
                            onPress={() => onPress?.(item)} />
                    </DView>))
            }
        </DView>

    )
}

export default Subscription;
