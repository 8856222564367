import {CommonActions, createNavigationContainerRef, StackActions} from "@react-navigation/native";
import {AppStackParamList, AuthStackParamList, RootStackParamList, RootTabParamList} from "../types.web";

export const navigationRef = createNavigationContainerRef<AppStackParamList>();

export type ParamList = RootStackParamList & AuthStackParamList & RootTabParamList;

export function navigate<V extends keyof ParamList, T extends ParamList>(name: V, params?: T[V]) {
    if (navigationRef.isReady()) {
        // @ts-ignore @FIXME: type inferal should be fixed
        navigationRef.navigate(name, params);
    }
}

export function navigateBack() {
    if (navigationRef.isReady()) {
        navigationRef.dispatch(CommonActions.goBack());
    }
}

export function navigatePop() {
    if (navigationRef.isReady()) {
        navigationRef.dispatch(StackActions.popToTop());
    }
}

export function navigateReplace<V extends keyof RootStackParamList, T extends RootStackParamList>(name: V, params?: T[V]) {
    if (navigationRef.isReady()) {
        navigationRef.dispatch(StackActions.replace(name, params));
    }
}

export function navigateToRoot() {
    if (navigationRef.isReady()) {
        navigationRef.dispatch(StackActions.popToTop());
    }
}

export function navigateAndReset(routes = [] as any[], index = 0) {
    if (navigationRef.isReady()) {
        navigationRef.dispatch(
            CommonActions.reset({
                index,
                routes,
            })
        );
    }
}

export function navigateAndSimpleReset(name: string, index = 0) {
    if (navigationRef.isReady()) {
        navigationRef.dispatch(
            CommonActions.reset({
                index,
                routes: [{name}],
            })
        );
    }
}

export function pushToRoot(name: string, params: any) {
    if (navigationRef.isReady()) {
        const root = navigationRef.getState()?.routes?.[0].state?.routes[0];
        navigationRef.dispatch(
            CommonActions.reset({
                index: root ? 1 : 0,
                routes: root ? [root as any, {name, params}] : [{name, params}],
            })
        );
    }
}
