import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ScrollView, View as DView } from "react-native";
import Chips from "../components/Chips";
import { Input } from "../components/EditableInput";
import Tabs from "../components/Tabs.web";
import { Button } from "../components/Themed";
import { S } from "../constants/Styles";
import { useColors } from "../hooks/ThemeContext";
import { userStore } from "../stores/userStore/UserStore";

const UserProfileScreen = observer(() => {
    const [activeScreen, setActiveScreen] = useState(0);

    return (
        <DView style={[S.flex, S.ph2, S.pt1]}>
            <ScrollView style={[S.flex]} showsVerticalScrollIndicator={false}
                        contentContainerStyle={[S.flexGrow1, S.pb4]}
                        keyboardShouldPersistTaps={"handled"}
                        keyboardDismissMode="on-drag">
                <DView
                    style={[S.flex, S.radius2, S.overflowHidden, S.pv2, S.m1, S.elevation, {position: 'relative'}]}
                    collapsable={false}>
                    {userStore.role === 'instructor' ?
                        <Instructor activeScreen={activeScreen}/> :
                        <User/>}
                </DView>

            </ScrollView>
            {userStore.role === 'instructor' ? <DView style={[S.ph2]}>
                <DView style={[S.wFull, S.center, S.mt3, {position: 'absolute', bottom: 0, left: 0}]}>
                    <Tabs tabs={[{name: 'Profile'}, {name: 'More Information'}]} activeScreen={activeScreen}
                          onPress={setActiveScreen} bg={'inputBackround'}
                          contentContainerStyle={{width: '80%'}}/>
                </DView>
            </DView> : null}
        </DView>
    );
});

interface InstructorProps {
    activeScreen: number,
}

const Instructor =
    (({activeScreen}: InstructorProps) => {

        return <DView style={[S.flex, {position: 'relative'}]}>
            {activeScreen === 0 ? <InstructorFields1/> : <InstructorFields2/>}
        </DView>
    })

const InstructorFields1 = observer(() => {
    const colors = useColors();
    const {name, summary, phone, age} = userStore;
    const [inputs, setInputs] = useState({name, summary, phone, age});
    const handleInput =
        (key: string, isNumber = false) =>
            (value: string) => {
                let val = value as string | number;
                if (isNumber) val = Number(value.replace(/[^0-9]/g, "")) || 0;
                if (!isNumber) val = String(val).replace(/[^0-9A-Za-z' ]/, '').replace(/  +/g, ' ')
                runInAction(() => {
                    setInputs(prev => ({...prev, [key]: val}));
                })
            };

    return (
        <DView style={[S.ph2]}>
            <Input color="accent" placeholder="About me" value={inputs['summary']}
                   onChangeText={handleInput("summary")} multiline numberOfLines={4} style={[S.P1]}
                   containerStyle={S.mb2}/>

            <Input color="accent" placeholder="Name" maxLength={40} value={inputs['name']}
                   onChangeText={handleInput("name")} style={[S.P1]} containerStyle={S.mb2}/>

            <Input editable={false} color="accent" placeholder="Email" maxLength={40} value={userStore.email}
                   style={[S.P1]} containerStyle={S.mb2}/>

            <Input color="accent" placeholder="Age" maxLength={2} keyboardType="numeric"
                   value={inputs['age'] + ""} onChangeText={handleInput("age", true)}
                   style={[S.P1]} containerStyle={S.mb2}/>

            <Input color="accent" placeholder="Phone" maxLength={20} value={inputs['phone']}
                   onChangeText={handleInput("phone")} style={[S.P1]} containerStyle={S.mb3}/>
            <DView style={[S.flex, S.center, S.end]}>
                <Button style={{width: 200}} onPress={() => userStore.save(inputs)} title="Save"
                        buttonStyle={{backgroundColor: colors.accent}}
                        titleStyle={{color: colors.white}}/>
            </DView>
        </DView>
    )
})

type ChipsKey = 'certifications' | 'education' | 'skills' | 'experience'
const InstructorFields2 = observer(() => {
    const colors = useColors();
    const {certifications, education, experience, skills} = userStore;
    const [chips, setChips] = useState({certifications, education, skills, experience});
    const handleChip = (key: ChipsKey) => (value: string) => {
        let vals = chips[key];
        vals = vals.concat(value);
        setChips(prev => ({...prev, [key]: vals}))
    }

    const removeChip = (key: ChipsKey) => (index: number) => {
        console.log(chips)
        const vals = chips[key];
        setChips(prev => ({...prev, [key]: vals.filter(val => val !== vals[index])}))
    }

    if (userStore.role !== 'instructor') return null;
    return (
        <DView style={[S.flex, S.ph2]}>
            <Chips title="Certifications" data={chips['certifications']}
                   titleStyle={{color: colors.placeholderTextColor}}
                   containerStyle={[S.mb2, S.wFull, {backgroundColor: colors.inputBackround}]}
                   handleChip={handleChip("certifications")}
                   onPress={removeChip('certifications')}/>

            <Chips title="Education" data={chips['education']}
                   titleStyle={{color: colors.placeholderTextColor}}
                   containerStyle={[S.mb2, S.wFull, {backgroundColor: colors.inputBackround}]}
                   handleChip={handleChip("education")}
                   onPress={removeChip('education')}/>

            <Chips title="Experience" data={chips['experience']}
                   titleStyle={{color: colors.placeholderTextColor}}
                   containerStyle={[S.mb2, S.wFull, {backgroundColor: colors.inputBackround}]}
                   handleChip={handleChip("experience")}
                   onPress={removeChip('experience')}/>

            <Chips title="Skills" data={chips['skills']}
                   titleStyle={{color: colors.placeholderTextColor}}
                   containerStyle={[S.mb2, S.wFull, {backgroundColor: colors.inputBackround}]}
                   handleChip={handleChip("skills")}
                   onPress={removeChip('skills')}/>
            <DView style={[S.flex, S.end, S.center]}>
                <Button style={{width: 200}} onPress={() => userStore.save(chips)} title="Save"
                        buttonStyle={{backgroundColor: colors.accent}}
                        titleStyle={{color: colors.white}}/>
            </DView>
        </DView>
    )
})

const User = observer(() => {
    const colors = useColors();
    const {name, summary, phone, age} = userStore;
    const [inputs, setInputs] = useState({name, summary, phone, age});
    const handleInput =
        (key: string, isNumber = false) =>
            (value: string) => {
                let val = value as string | number;
                if (isNumber) val = Number(value.replace(/[^0-9]/g, "")) || 0;
                runInAction(() => {
                    setInputs(prev => ({...prev, [key]: val}));
                })
            };

    return <DView style={[S.flex, S.ph2]}>
        <Input color="accent" placeholder="About me" value={inputs['summary']}
               onChangeText={handleInput("summary")} multiline numberOfLines={4} style={[S.P1]} containerStyle={S.mb2}/>

        <Input color="accent" placeholder="Name" maxLength={40} value={inputs['name']}
               onChangeText={handleInput("name")} style={[S.P1]} containerStyle={S.mb2}/>

        <Input editable={false} color="accent" placeholder="Email" maxLength={40} value={userStore.email}
               style={[S.P1]} containerStyle={S.mb2}/>

        <Input color="accent" placeholder="Age" maxLength={2} keyboardType="numeric"
               value={inputs['age'] + ""} onChangeText={handleInput("age", true)}
               style={[S.P1]} containerStyle={S.mb2}/>

        <Input color="accent" placeholder="Phone" maxLength={20} value={inputs['phone']}
               onChangeText={handleInput("phone")} style={[S.P1]} containerStyle={S.mb3}/>
        <DView style={[S.flex, S.end, S.center]}>
            <Button style={{width: 200}} onPress={() => userStore.save(inputs)} title="Save" buttonStyle={{backgroundColor: colors.accent}}
                    titleStyle={{color: colors.white}}/>
        </DView>
    </DView>
})

export default UserProfileScreen;
