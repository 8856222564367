import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isEqual from "date-fns/isEqual";
import isWithinInterval from "date-fns/isWithinInterval";
import parse from "date-fns/parse";
import Toast, { ToastOptions } from "react-native-root-toast";
import Colors from "../constants/Colors";
import { S } from "../constants/Styles";

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const showToast = (msg: string, duration: "LONG" | "SHORT" = "SHORT", bg?: string, options?: Omit<ToastOptions, "duration" | "animation">) => {
	const backgroundColor = Colors.dark.accent;
	Toast.show(msg, {
		duration: Toast.durations[duration],
		animation: true,
		backgroundColor: bg ? bg : backgroundColor,
		position: Toast.positions.CENTER,
		textStyle: {
			...S.H3,
		},
		containerStyle: {
			...S.ph3,
			...S.pv2,
		},
		...options,
	});
};
export const validateEmail = (email: string) => {
	return emailRegex.test(email);
};

export const sanitizeEmail = (email: string) => {
	// Remove leading and trailing whitespace
	const sanitizedEmail = email.replace(/\s/, "");
	return sanitizedEmail;
};
export const dateFormat = "yyyy-MM-dd";
export const dateTimeFormat = "yyyy-MM-dd HH:mm";
export const timeFormat = "HH:mm";

export const currentTime = () => {
	const d = new Date();
	return d.getHours() + ":" + d.getMinutes();
};

export const isDateBefore = (dateString: string) => {
	const today = new Date();
	const date = parse(dateString, dateFormat, today);
	return isBefore(date, today);
};

export const isDateAfter = (dateString: string) => {
	const today = new Date();
	const date = parse(dateString, dateFormat, today);
	return isAfter(date, today);
};
export const isSameOrAfter = (dateString: string) => {
	const today = new Date();
	const date = parse(dateString, dateFormat, today);
	const isDateAfter = isAfter(date, today);
	const isDateEqual = isEqual(date, today);

	return isDateEqual || isDateAfter;
};

export const isSameOrBefore = (dateString: string) => {
	const today = new Date();
	const date = parse(dateString, dateFormat, today);
	const isDateBefore = isBefore(date, today);
	const isDateEqual = isEqual(date, today);

	return isDateEqual || isDateBefore;
};

export const isBetween = (startDate: string, endDate: string) => {
	const today = new Date();
	const start = parse(startDate, dateFormat, today);
	const end = parse(endDate, dateFormat, today);
	return isWithinInterval(today, { start, end });
};

export const formatUIDate = (date: string | Date) => {
	if (typeof date === "string") return format(parse(date, dateFormat, new Date()), "MMM dd, yyyy");
	else return format(date, "MMM dd, yyyy");
};

interface HSLColor {
    h?: number;
    s: number;
    l: number;
  }

export function generateDistinctLightColor(previousColors: string[]) {
    const maxAttempts = 10; // Maximum number of attempts to generate a distinct color
  
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      const hue = Math.floor(Math.random() * 360);
      const saturation = Math.floor(Math.random() * 30) + 70;
      const lightness = Math.floor(Math.random() * 30) + 70;
  
      let isDistinct = true;
      for (let i = 0; i < previousColors.length; i++) {
        const previousColor = previousColors[i];
        const colorDiff = Math.abs(hue - hexToHue(previousColor));
  
        // Check if the hue difference is within an acceptable threshold
        if (colorDiff < 30) {
          isDistinct = false;
          break;
        }
      }
  
      if (isDistinct) {
        const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
        return color;
      }
    }
  
    // If a distinct color couldn't be generated, return a fallback color
    return "#FFFFFF"; // Fallback to white if needed
  }
  
  function hexToHue(hexColor: string): number {
    // Remove the leading "#" symbol if present
    const color = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;
  
    // Extract the red, green, and blue values from the hexadecimal color
    const red = parseInt(color.substring(0, 2), 16);
    const green = parseInt(color.substring(2, 4), 16);
    const blue = parseInt(color.substring(4, 6), 16);
  
    // Convert RGB values to HSL and extract the hue value
    const hsl = rgbToHsl(red, green, blue);
    return hsl?.h || 0;
  }
  
  function rgbToHsl(r: number, g: number, b: number): HSLColor {
    r /= 255;
    g /= 255;
    b /= 255;
  
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;
  
    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
  
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
  
      if(h)
        h /= 6;
    }
  
    return { h, s, l };
  }