import { forwardRef, ReactNode, useEffect, useImperativeHandle } from 'react';
import { Platform, Pressable, StyleSheet, useWindowDimensions, View } from 'react-native';
import { GestureHandlerRootView, PanGestureHandler } from 'react-native-gesture-handler';
import Animated, {
    interpolate,
    runOnJS,
    SharedValue,
    useAnimatedGestureHandler,
    useAnimatedKeyboard,
    useAnimatedStyle,
    useSharedValue,
    withSpring,
    WithSpringConfig,
    withTiming
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { S } from '../constants/Styles';
import { ColorType, useColors } from "../hooks/ThemeContext";
import { WebWrapper } from "./WebWrapper";

interface Props {
    goBack: any;
    body: React.ReactNode;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    noHandle?: boolean;
    nokeyboard?: boolean;
    bg?: ColorType;
}

export const CustomModal = forwardRef<
    { onRequestClose: () => void },
    Props
>(({goBack, bg, body, footer, header, noHandle, nokeyboard}, ref) => {
    const insets = useSafeAreaInsets();
    const colors = useColors();
    const YZERO = 1000;
    const translateY = useSharedValue(YZERO);
    const opacity = useSharedValue(1);
    const color = bg ? colors[bg] : colors.accent;
    const {width} = useWindowDimensions();
    const modalPadding = width <1200 ? {paddingHorizontal: 0} : {}
    const keyboard =
        // eslint-disable-next-line react-hooks/rules-of-hooks
        Platform.OS === 'web' ? {height: {value: 0}} : useAnimatedKeyboard();

    const back = () => {
        goBack();
    }
    const onRequestClose = () => {
        translateY.value = withTiming(YZERO, {duration: 500});
        back();
    };

    useImperativeHandle(ref, () => ({
        onRequestClose,
        back,
    }));

    const onGestureEvent = useAnimatedGestureHandler({
        // Set the context value to the sheet's current height value
        onStart: (_ev, ctx: any) => {
            ctx.offsetY = translateY.value;
        },
        onActive: (ev, ctx: any) => {
            if (ev.translationY > 0) {
                translateY.value = ctx.offsetY + ev.translationY;
            }
        },
        // Snap the sheet to the correct position once the gesture ends
        onEnd: ev => {
            // 'worklet' directive is required for animations to work based on shared values
            ('worklet');

            // move ment was too small.
            if (Math.abs(ev.translationY) < 120) {
                translateY.value = withSpring(0, springConfig);
                return;
            }

            translateY.value = withTiming(YZERO, {duration: 500});
            runOnJS(back)();
        },
    });
    useEffect(() => {
        translateY.value = withSpring(0, springConfig);
    }, [translateY]);

    const frontrowAnimatedStyle = useAnimatedStyle(() => ({
        // The 'worklet' directive is included with useAnimatedStyle hook by default
        opacity: opacity.value,
        transform: [{translateY: translateY.value}],
    }));
    const keyboardStyle = useAnimatedStyle(() => ({
        transform: [{translateY: -keyboard.height.value}],
    }));

    return (
        <GestureHandlerRootView
            style={[S.flex, S.wFull, S.end, S.selfCenter, StyleSheet.absoluteFill]}>
            <Backdrop
                onRequestClose={goBack === null ? undefined : onRequestClose}
                opacity={translateY}
            >
                <Animated.View style={nokeyboard ? {} : keyboardStyle}>
                    <PanGestureHandler
                        onGestureEvent={goBack === null ? undefined : onGestureEvent}
                        failOffsetX={[-5, 5]}
                        activeOffsetY={[-15, 15]}>
                        <Animated.View style={[frontrowAnimatedStyle]}>
                            <View style={[S.radiusTop, S.shadowXl]}>
                                <WebWrapper style={modalPadding}>
                                    <>
                                        {header && header}
                                        {body}
                                    </>
                                </WebWrapper>
                            </View>
                            {footer && (
                                <View style={{paddingBottom: insets.bottom}}>
                                    {footer}
                                </View>
                            )}
                        </Animated.View>
                    </PanGestureHandler>
                </Animated.View>
            </Backdrop>
        </GestureHandlerRootView>
    );
});

function Backdrop({
                      onRequestClose,
                      opacity,
                      children
                  }: {
    onRequestClose?: CallableFunction;
    opacity: SharedValue<number>;
    children?: ReactNode
}) {
    const animatedStyle = useAnimatedStyle(() => ({
        opacity: interpolate(opacity.value, [1000, 0], [0, 1]),
    }));

    return (
        <Animated.View style={[animatedStyle, StyleSheet.absoluteFill, S.backdrop, S.end]}>
            <Pressable
                onPress={() => onRequestClose && onRequestClose()}
                style={[S.flex, S.backdrop, S.end]}
            >
                {children}
            </Pressable>
        </Animated.View>
    );
}

const springConfig: WithSpringConfig = {
    damping: 50,
    mass: 0.3,
    stiffness: 120,
    overshootClamping: false,
    restSpeedThreshold: 0.3,
    restDisplacementThreshold: 0.3,
};
