import axios from "axios";

export const BASE_URL = 'https://api.befitladies.ae/v1/'
export const api = axios.create({
    baseURL: `${BASE_URL}`,
});

api.interceptors.request.use((request) => {
    // console.log('REQUEST', request.url, request.data, request.headers.Authorization);
    return request;
})
api.interceptors.response.use((response) => {
    // console.log('RESPONSE', response.data)
    return response;
})
