import React, { useState } from 'react';
import { ActivityIndicator, useWindowDimensions, View as DefaultView } from 'react-native';
import { IInstructorSubscription } from '../../api/types';
import { SubscriptionCard } from '../../components/SubscriptionCard.web';
import { View } from '../../components/Themed';
import { S } from '../../constants/Styles';
import { useInstructorSubscriptions } from '../../hooks/queries/useInstructorSubscriptions';
import { navigate } from '../../navigation/helpers';
import ScheduleContainer from './ScheduleContainer';
export const InstructorSchedule = () => {
    const [subsToShow, setSubsToShow] = useState<IInstructorSubscription[]>([]);
    const instructorSubscriptions = useInstructorSubscriptions();
    
    if (instructorSubscriptions.loading) return <ActivityIndicator />

    const { width } = useWindowDimensions();

    let grid = { gridTemplateColumns: 'repeat(4, 1fr)' };
    if (width < 1200 && width >= 768) {
        grid = { gridTemplateColumns: 'repeat(2, 1fr)' };
    } else if (width < 768) {
        grid = { gridTemplateColumns: 'repeat(1, 1fr)' }
    }

    return (
        <View style={S.flex} bg="transparent">
            <ScheduleContainer subscriptions={instructorSubscriptions.activeSubscriptions.concat(instructorSubscriptions.upcomingSubscriptions)} setSubsToShow={setSubsToShow} />
            <DefaultView style={[S.mt2, S.mh2]}>
                    <div style={{ display: 'grid', gap: 16, ...grid, }}>
                        {subsToShow.map(sub => <SubscriptionCard key={sub.id} sub={sub} variableColor={'accent'} onPress={() => navigate('InstructorClass', {subscriptionId: sub.id})}/>)}
                    </div>
                </DefaultView>
        </View>
    )
}