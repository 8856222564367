import React from 'react';
import { Image, useWindowDimensions, type StyleProp, type TextStyle } from 'react-native';
import { IClass, IClassPhoto } from '../../api/types';
import { S } from '../../constants/Styles';
import { Text, View, type ViewProps } from '../Themed';

const ClassCard = ({
    c,
    image,
    sideOffset,
    showDescription = false,
    titleStyle,
    descriptionStyle,
    containerStyle
}: { c: IClass; image?: IClassPhoto, sideOffset?: number; showDescription?: boolean; titleStyle?: StyleProp<TextStyle>; descriptionStyle?: StyleProp<TextStyle>; containerStyle?: ViewProps['style'] }) => {
    const { width } = useWindowDimensions()

    const widthRule = sideOffset ? [{ width: width - sideOffset, marginLeft: sideOffset / 2 }] : null;

    return <View key={c.id} style={[containerStyle, widthRule]} >
        {image?.photo ? <>
            <Image
                resizeMode={'cover'}
                style={[S.radiusBottom2, { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }]}
                source={{ uri: image.photo }} />
            {/* <LinearGradient
                colors={['transparent', 'transparent', 'rgba(0,0,0,0.8)']}
                style={[S.radiusBottom2, { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 2 }]} /> */}
        </> : null}
        <Text
            color={'white'}
            style={[titleStyle, { zIndex: 3 }]}>
            {c.name}
        </Text>

        {showDescription ? (
            <Text
                color={'white'}
                style={[descriptionStyle]}>
                {c.description}
            </Text>
        ) : null}
    </View>;
};

export default ClassCard;
