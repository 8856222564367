import {useQuery} from "@tanstack/react-query";
import {getUserAbsent} from "../../api/client";
import {IUserAbsent} from "../../api/types";
import {queryClient} from "../QueryProvider";

export const useUserAbsent = () => {

    const invalidate = () => queryClient.invalidateQueries(['userAttendance'])

    const query = useQuery({
        queryKey: ["absentUsers"],
        queryFn: () => getUserAbsent(),
        // suspense: true,
    });

    let data = [] as IUserAbsent[];
    if (!query.isLoading && query?.data) data = query?.data;

    return {
        data,
        invalidate,
        loading: query?.isLoading,
    };
};
