import { useQuery } from "@tanstack/react-query";
import { getAllClasses } from "../../api/client";
import { IClass } from "../../api/types";
import { queryClient } from "../QueryProvider";

export const useClasses = () => {
    const invalidate = () => queryClient.invalidateQueries(['allClasses'])
    const query = useQuery({
        queryKey: ["allClasses"],
        queryFn: () => getAllClasses(),
        networkMode: 'offlineFirst'
        // suspense: true,
    });

    let data = [] as IClass[];
    if (!query.isLoading && query?.data) data = query?.data;

    return {
        data,
        invalidate,
        loading: query?.isLoading,
    };
};
