import React, { Dispatch, useContext } from "react";

import Colors from "../constants/Colors";

export type ThemeColors = "light" | "dark";
export type ColorType = keyof typeof Colors.dark;

const ThemeContext = React.createContext<{ theme: ThemeColors; setTheme: Dispatch<React.SetStateAction<ThemeColors>>; colors: typeof Colors.dark }>({
	theme: "dark",
	setTheme: () => {},
	colors: Colors.light,
});

export function useColors() {
	const { theme } = useContext(ThemeContext);
	return Colors[theme];
}

export function useTheme() {
	return useContext(ThemeContext);
}
export default ThemeContext;
