import {ReactElement} from "react";
import {S} from '../constants/Styles';
import {StyleProp, View, ViewStyle} from 'react-native';

export function WebWrapper({children, style}: { children: ReactElement, style?: StyleProp<ViewStyle> }) {
    return (
        <View style={[S.wFull, S.flex, {paddingHorizontal: '30%'}, style]}>
            {children}
        </View>
    );
}
