import { QueryClient, QueryClientProvider as DefaultQueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// suspense: true,
			staleTime: 1000 * 60 * 5, // 5minutes
		},
	},
});

export const QueryClientProvider = ({ children }: { children: ReactNode | ReactNode[] }) => <DefaultQueryClientProvider client={queryClient}>{children}</DefaultQueryClientProvider>;
