import {
    createDrawerNavigator,
    DrawerContentComponentProps,
    DrawerContentScrollView,
    DrawerItem,
    DrawerItemList
} from '@react-navigation/drawer';
import { memo } from "react";
import { useWindowDimensions, View as DefaultView } from 'react-native';
import { UserCard } from "../components/UserCard";
import { S } from '../constants/Styles';
import { useColors } from "../hooks/ThemeContext";
import CalendarScreen from "../screens/Classes/CalendarScreen.web";
import ClassesScreen from "../screens/Classes/ClassesScreen";
import { InstructorSchedule } from '../screens/Schedule/InstructorSchedule';
import UserSchedule from '../screens/Schedule/UserSchedule.web';
import SettingsScreen from "../screens/SettingsScreen";
import { InstructorClass } from "../screens/Subscriptions/InstructorClass";
import InstructorClassesScreen from "../screens/Subscriptions/InstructorClasses";
import SubscriptionsScreen from "../screens/Subscriptions/SubscriptionsScreen";
import UserProfileScreen from "../screens/UserProfileScreen";
import { userStore } from "../stores/userStore/UserStore";
import { RootTabParamList } from "../types.web";

const CustomDrawer = (props: DrawerContentComponentProps) => {
    const colors = useColors();
    return (
        <DrawerContentScrollView {...props}>
            <DefaultView style={[S.ph2]}>
                <UserCard imgSize={150} />
            </DefaultView>
            <DrawerItemList {...props} />
            <DrawerItem
                key={'Logout'}
                label={
                    'Log Out'
                }
                labelStyle={[S.P1]}
                onPress={() => userStore.logOut()}
                inactiveTintColor={colors.brightRed}
                inactiveBackgroundColor={colors.transparent}
                activeTintColor={colors.brightRed}
                activeBackgroundColor={colors.transparent}
            />
        </DrawerContentScrollView>
    )
}

const BottomTab = createDrawerNavigator<RootTabParamList>();

const TabBar = memo(() => {
    const colors = useColors();
    const { width } = useWindowDimensions();
    const role = userStore.role;
    return (
        <BottomTab.Navigator
            drawerContent={(props) => <CustomDrawer {...props} />}
            initialRouteName={userStore.role === 'instructor' ? 'InstructorClasses' : 'Subscriptions'}
            screenOptions={{
                drawerType: width < 1200 ? 'slide' : 'permanent',
                drawerStyle: {
                    backgroundColor: colors.transparent,
                    borderRightWidth: 0,
                    marginRight: 32
                },
                drawerItemStyle: { ...S.radius1 },
                drawerLabelStyle: { ...S.P1 },
                drawerInactiveTintColor: colors.accent,
                drawerInactiveBackgroundColor: colors.transparent,
                drawerActiveTintColor: colors.white,
                drawerActiveBackgroundColor: colors.accent,
                headerShown: width < 1200,
                headerStyle: { backgroundColor: colors.transparent, elevation: 0, borderBottomWidth: 0 },
                headerTitleStyle: { fontSize: 25 },
                headerTintColor: colors.accent,
                unmountOnBlur: true
            }}
        >

            {role === 'instructor' ?
                <BottomTab.Screen name='InstructorClasses' component={InstructorClassesScreen} options={{
                    title: 'Instructor Classes',
                }} /> : null}
            {role === 'instructor' ?
                <BottomTab.Screen name='InstructorClass' component={InstructorClass} options={{
                    title: 'Instructor Subscriptions',
                    drawerItemStyle: { height: 0, ...S.pReset, ...S.mReset }
                }} /> : null}
            {role === 'instructor' ?
                <BottomTab.Screen name='InstructorSchedule' component={InstructorSchedule} options={{
                    title: 'My Schedule',
                }} /> : null}
            {role === 'user' ?
                <BottomTab.Screen name='UserSchedule' component={UserSchedule} options={{
                    title: 'My Schedule',
                }} /> : null}
            {role === 'user' ? <BottomTab.Screen
                name='Classes'
                component={ClassesScreen}
                options={{
                    title: 'Classes',
                }}
            /> : null}
            {role === 'user' ? <BottomTab.Screen name='CalendarScreen' component={CalendarScreen} options={{
                title: 'Calendar',
                drawerItemStyle: { height: 0, ...S.pReset, ...S.mReset }
            }} /> : null}
            {role === 'user' ? <BottomTab.Screen name='Subscriptions' component={SubscriptionsScreen} options={{
                title: 'Subscriptions',
            }} /> : null}
            <BottomTab.Screen name='UserProfile' component={UserProfileScreen} options={{
                title: 'Profile',
            }} />
            <BottomTab.Screen name='Settings' component={SettingsScreen} options={{
                title: 'Settings',
            }} />

        </BottomTab.Navigator>
    );
});

export default TabBar;
