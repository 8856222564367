import React from "react";
import { Image, View as DView } from 'react-native';
import { api } from "../../../api/api";
import { Input } from "../../../components/EditableInput";
import { Logo } from "../../../components/Icons/Icons";
import { Button, View } from "../../../components/Themed";
import { S } from "../../../constants/Styles";
import { sanitizeEmail, showToast, validateEmail } from "../../../helpers/helpers";
import { useColors } from "../../../hooks/ThemeContext";
// @ts-ignore
import img from "../../../../assets/images/main.jpg";
import { WebWrapper } from "../../../components/WebWrapper";

const SignUpScreen = () => {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const colors = useColors();
    const signup = async () => {
        if (email.length === 0 || password.length === 0) {
            showToast("Please fill email and password");
            return;
        }
        if (password.length < 6) {
            showToast("Password should be between 6 and 20 characters");
            return;
        }
        if (!validateEmail(email)) {
            showToast("Email is invalid");
            return;
        }

        return api
            .post("auth/sign-up", {
                email,
                password,
            })
            .then((e) => {
                showToast(e?.data?.message || 'Please verify your email')
            })
            .catch((err) => {
                console.log(err)
                showToast(err?.response?.data?.message || 'Sign up failed', "LONG");
                console.error(err);
            });
    };

    const handleEmail = (email: string) => {
        setEmail(sanitizeEmail(email));
    };
    return (
        <View style={[S.flex]} bg={"accent"}>
            <Image style={[S.wFull, S.hFull, {position: 'absolute', top: 0, right: 0, left: 0}]}
                   source={{uri: img}}/>
            <WebWrapper>
                <DView style={[S.flex, S.justify]}>
                    <DView style={[S.flex, S.end, S.center, S.mb3]}>
                        <Logo size={200}/>
                    </DView>
                    <DView style={[S.flex]}>
                        <Input placeholder="Email" maxLength={40} autoCapitalize={"none"} autoCorrect={false}
                               autoComplete="email"
                               textContentType="emailAddress" keyboardType="email-address" onChangeText={handleEmail}
                               value={email}
                               containerStyle={S.mb2}/>
                        <Input placeholder="Password" secureTextEntry={true} maxLength={20} autoCapitalize={"none"}
                               autoCorrect={false} textContentType="password" onChangeText={setPassword}
                               value={password}
                               containerStyle={S.mb3} onSubmitEditing={signup}/>
                        <Button buttonStyle={{backgroundColor: colors.accent}} titleStyle={{color: colors.white}}
                                type="solid"
                                containerStyle={S.mb2} title={"Sign up"} onPress={() => signup()}/>
                    </DView>
                </DView>
            </WebWrapper>
        </View>
    );
};

export default SignUpScreen;
