import {Avatar} from '@rneui/themed';
import * as DImagePicker from 'expo-image-picker';
import React, {useState} from 'react';
import {Pressable} from 'react-native';
import {userStore} from '../../stores/userStore/UserStore';
import {useProfilePhoto} from '../../hooks/queries/useProfilePhoto';
import {useColors} from '../../hooks/ThemeContext';
import {AxiosRequestConfig} from "axios";
import {api} from "../../api/api";

export default function ImagePicker({size = 150}: { size?: number }) {
    const [image, setImage] = useState('');
    const colors = useColors();
    const {query: photoQuery, invalidate} = useProfilePhoto();

    let oldImage = '';
    if (photoQuery.isSuccess && !!photoQuery.data?.photo) {
        oldImage = 'data:image/jpeg;base64,' + photoQuery.data.photo;
    }

    const pickImage = async () => {
        const result = await DImagePicker.launchImageLibraryAsync({
            mediaTypes: DImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 0.3,
        });
        if (result?.assets?.[0]) {
            const localUri = result.assets[0].uri;
            const filename = localUri?.split('/')?.pop() || (userStore.email + '-' + Math.random());

            const response = await fetch(localUri);
            const blob = await response.blob();
            // const newImageUri = "file:///" + localUri.split("file:/").join("");
            const formData = new FormData();
            // formData.append('photo', {
            //     uri: localUri,
            //     type: mime.getType(localUri),
            //     name: filename,
            // });

            formData.append('photo', blob, filename);

            const config: AxiosRequestConfig = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${userStore.accessToken}`
                },
                data: formData,
            };
            sendXmlHttpRequest(formData).then(res => {
                setImage(localUri)
                invalidate()
            }).catch((err) => {
                console.log(err)
            })
        }
    }


    return (
        <Pressable onPress={pickImage}>
            <Avatar
                size={size}
                rounded
                icon={{
                    name: "extension",
                    type: "material",
                    color: colors.accent,
                    size,
                }}
                source={{uri: image || oldImage || "https://cdn.pixabay.com/photo/2017/02/23/13/05/avatar-2092113__340.png"}}/>
        </Pressable>
    );
}

function sendXmlHttpRequest(data) {
    const xhr = new XMLHttpRequest();

    return new Promise((resolve, reject) => {
        xhr.onreadystatechange = e => {
            if (xhr.readyState !== 4) {
                return;
            }

            if (xhr.status === 200 || xhr.status === 201) {
                resolve(JSON.parse(xhr.responseText));
            } else {
                reject("Request Failed");
            }
        };
        xhr.open("POST", `${api.defaults.baseURL}users/photo`);
        xhr.setRequestHeader('Authorization', 'Bearer ' + userStore.accessToken);
        xhr.send(data);
    });
}

function b64toBlob(b64Data: any, contentType: any, sliceSize: any) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new File(byteArrays, "pot", {type: contentType});
}
