import AsyncStorage from "@react-native-async-storage/async-storage";
import {makeAutoObservable, runInAction, when} from "mobx";
import {api} from "../../api/api";
import {IUserInfo, UserRole} from "../../api/types";
import {Buffer} from "buffer";
import {queryClient} from "../../hooks/QueryProvider";

class UserStore {
    constructor() {
        makeAutoObservable(this);

        when(
            () => !this.accessToken,
            async () => this.logIn().finally(() => {
                runInAction(() => {
                    this.loading = false
                })
            })
        );
    }

    logIn = async () => {
        this.loading = true;
        // const refreshToken = (await AsyncStorage.getItem("refreshToken")) || "";
        //
        // if (!refreshToken) {
        //     await this.logOut();
        //     return;
        // }
        // const res = await api.post("auth/refresh-token", {
        //     refreshToken,
        // });
        // const tokens = res?.data;
        //
        // if (!tokens?.accessToken) this.logOut();

        const accessToken = (await AsyncStorage.getItem("accessToken")) || "";

        if (!accessToken) await this.logOut();

        await AsyncStorage.setItem("accessToken", accessToken);
        // await AsyncStorage.setItem("refreshToken", tokens.refreshToken);
        await queryClient.resetQueries();
        api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        const userInfo = await api.get<IUserInfo>("users").catch(err => {
            if (err?.response?.status === 401)
                this.logOut();
        });
        if (!userInfo?.data) return;

        userStore.update({
            accessToken: accessToken,
            refreshToken: '', ...userInfo.data
        });
    }

    userId = ""
    accessToken = "";
    refreshToken = "";
    email = "";
    name = "";
    age = 0;
    phone = "";
    summary = "";
    certifications: string[] = [];
    skills: string[] = [];
    education: string[] = [];
    experience: string[] = [];
    photo: Buffer | null = null;
    role: UserRole = 'user';
    loading = false;

    get UserInfo() {
        return {
            role: this.role,
            email: this.email,
            name: this.name,
            age: this.age,
            phone: this.phone,
            summary: this.summary,
            certifications: this.certifications,
            skills: this.skills,
            education: this.education,
            experience: this.experience,
            photo: this.photo,
        };
    }

    update = (userInfo: Partial<IUserInfo & { accessToken: string; refreshToken: string }>) => {
        Object.entries(this).forEach(([k, v]) => {
            // @ts-ignore
            if (typeof v !== "function" && userInfo?.[k]) {
                // @ts-ignore
                this[k] = userInfo?.[k];
            }
            if (userInfo.role) this.role = userInfo.role;
        });
        this.loading = false
    };

    save = (data: Partial<IUserInfo>) => {
        // const data = {userStore.UserInfo}
        if (!data.age) data.age = 0;
        return api.patch("users", data).then(() => {
            this.update(data);
        }).catch(console.error);
    }

    clear = () => {
        Object.keys(this).forEach(k => {
            const type = typeof this[k as keyof UserStore];
            if ((k in this && type === 'string') || type === 'number') {
                // @ts-ignore
                this[k] = '';
            }
        });
        this.certifications = [];
        this.experience = [];
        this.skills = [];
        this.education = [];
    }

    logOut = async () => {
        await AsyncStorage.removeItem("accessToken");
        await AsyncStorage.removeItem("refreshToken");
        this.clear();
    };
}

export const userStore = new UserStore();
