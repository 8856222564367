export default {
    light: {
        darkPurple: "#4b0082",
        // accent: "#7d47b8",
        accent: '#9E73C7',
        accent2: '#ee82ee',
        pink: "#ff69b4",
        brightRed: "#ff0000",
        deepOrange: "#ff8c00",
        brightYellow: "#ffff00",
        lightGreen: "#90ee90",
        background: "#ffffff",
        background2: "#f7f7f7",
        background3: "#1d073f",
        lightGrey: "#d3d3d3",
        darkGrey: "#959595",
        white: "#FFFFFF",
        xColor: "#999999",
        xColor2: "#111111",
        placeholderTextColor: "#999999",
        transparent: "transparent",
        black: "#000000",
        inputBackround: "#353746"
    },
    dark: {
        darkPurple: "#4b0082",
        accent: "#9E73C7",
        accent2: "#ee82ee",
        pink: "#ff69b4",
        brightRed: "#ff0000",
        deepOrange: "#ff8c00",
        brightYellow: "#ffff00",
        lightGreen: "#90ee90",
        background: "#1d073f",
        background2: "#333333",
        background3: "#1d073f",
        lightGrey: "#d3d3d3",
        darkGrey: "#959595",
        white: "#FFFFFF",
        xColor: "#FFFFFF",
        xColor2: "#FFFFFF",
        placeholderTextColor: "#999999",
        transparent: "transparent",
        black: "#000000",
        inputBackround: "#353746"
    },
};
