import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback, useMemo, useState } from "react";
import { ScrollView, useWindowDimensions, View as DView } from "react-native";
import Empty from "../../components/Empty";
import { User } from "../../components/Home/User";
import { SubscriptionCard } from "../../components/SubscriptionCard";
import Tabs from "../../components/Tabs.web";
import { Text } from "../../components/Themed";
import { S } from "../../constants/Styles";
import { useUserSubscriptions } from "../../hooks/queries/useUserSubscriptions";
import { RootStackScreenProps } from "../../types";

const SubscriptionsScreen = ({route}: RootStackScreenProps<'Subscriptions'>) => {
    const {activeSubscriptions, expiredSubscriptions, upcomingSubscriptions, invalidate} = useUserSubscriptions();
    const initialIndex = route?.params?.index ?? 1;
    const [activeScreen, setActiveScreen] = useState(initialIndex);

    useFocusEffect(useCallback(
            () => {
                setActiveScreen(initialIndex ?? 1);
                invalidate()
            },
            [],
        )
    )
    const tabs = useMemo(() => [{name: 'Upcoming'}, {name: 'Active'}, {name: 'Expired'}], []);
    const {width} = useWindowDimensions();
    let grid = { gridTemplateColumns: 'repeat(4, 1fr)' };
    if (width < 1200 && width >= 768) {
        grid = { gridTemplateColumns: 'repeat(2, 1fr)' };
    } else if (width < 768) {
        grid = { gridTemplateColumns: 'repeat(1, 1fr)' }
    }
    return (
        <DView style={[S.flex, S.pt2, S.pb4, S.ph2]} key={initialIndex}>
            <ScrollView contentContainerStyle={S.flexGrow1} showsVerticalScrollIndicator={false}>
                <User/>
                <Text variant={'H1'} style={[S.mt3, S.mb2]}>Subscriptions</Text>
                {activeScreen === 0 ?
                    upcomingSubscriptions.length ?
                        <div style={{display: 'grid', gap: 16, ...grid}}>
                            {upcomingSubscriptions.map(sub =>
                                <SubscriptionCard key={sub.id} sub={sub.subscription}/>
                            )}
                        </div>
                        : <Empty title={'No Upcoming Subscriptions'}/>
                    : null}
                {activeScreen === 1 ?
                    activeSubscriptions.length ?
                        <div style={{display: 'grid', gap: 16, ...grid}}>
                            {activeSubscriptions.map(sub =>
                                <SubscriptionCard key={sub.id} sub={sub.subscription}/>
                            )}
                        </div>
                        : <Empty title={'No Active Subscriptions'}/>
                    : null}
                {activeScreen === 2 ?
                    expiredSubscriptions.length ?
                        <div style={{display: 'grid', gap: 16, ...grid}}>
                            {expiredSubscriptions.map(sub =>
                                <SubscriptionCard key={sub.id} sub={sub.subscription}/>
                            )}
                        </div>
                        : <Empty title={'No Expired Subscriptions'}/>
                    : null}
            </ScrollView>
            <DView style={[S.wFull, S.center, S.mt3, {position: 'absolute', bottom: 0, left: 0}]}>
                <Tabs tabs={tabs} activeScreen={activeScreen} onPress={setActiveScreen} bg={'inputBackround'}
                      contentContainerStyle={{width: '80%'}}/>
            </DView>
        </DView>
    );
};

export default SubscriptionsScreen;
