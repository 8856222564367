import { useQuery } from "@tanstack/react-query"
import { getInstructorSubscriptions } from "../../api/client"
import { IInstructorSubscription } from "../../api/types"
import { isBetween, isDateAfter, isDateBefore } from "../../helpers/helpers"
import { userStore } from "../../stores/userStore/UserStore"
import { queryClient } from "../QueryProvider"

export const useInstructorSubscriptions = () => {
    const queryKey = ['instructorSubscriptions']
    const invalidate = () => queryClient.invalidateQueries(queryKey)

    const query = useQuery({
        queryFn: () => getInstructorSubscriptions(),
        queryKey,
        // suspense: true,
        staleTime: 0,
        enabled: userStore.role === 'instructor'
    })

    let activeSubscriptions = [] as IInstructorSubscription[];
    let upcomingSubscriptions = [] as IInstructorSubscription[];
    let expiredSubscriptions = [] as IInstructorSubscription[];


    if (query.isSuccess && query?.data?.length > 0) {
        const instructorSubscriptions = query.data;

        activeSubscriptions = instructorSubscriptions.filter((instructorSubscription) => isBetween(instructorSubscription.dateFrom, instructorSubscription.dateTo))

        upcomingSubscriptions = instructorSubscriptions.filter((instructorSubscription) => isDateAfter(instructorSubscription.dateFrom))

        expiredSubscriptions = instructorSubscriptions.filter((instructorSubscription) => isDateBefore(instructorSubscription.dateTo))
    }

    return {
        loading: query.isLoading,
        invalidate,
        activeSubscriptions,
        upcomingSubscriptions,
        expiredSubscriptions,
        query
    }
}
