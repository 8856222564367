import { useFocusEffect } from "@react-navigation/native";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { ScrollView, useWindowDimensions, View as DView } from "react-native";
import PagerView from "react-native-pager-view";
import Empty from "../../components/Empty";
import { SubscriptionCard } from "../../components/SubscriptionCard";
import Tabs from "../../components/Tabs.web";
import { S } from "../../constants/Styles";
import { useInstructorSubscriptions } from "../../hooks/queries/useInstructorSubscriptions";
import { navigate } from "../../navigation/helpers";

const InstructorClassesScreen = () => {
    const ref = useRef<PagerView>(null);
    const {
        activeSubscriptions,
        expiredSubscriptions,
        upcomingSubscriptions,
        invalidate,
        loading
    } = useInstructorSubscriptions();
    // console.log(activeSubscriptions);
    const initialIndex = 1;
    const [activeScreen, setActiveScreen] = useState(initialIndex);

    useFocusEffect(useCallback(
            () => {
                setActiveScreen(initialIndex ?? 1);
                invalidate()
            },
            [],
        )
    )
    const tabs = useMemo(() => [{name: 'Upcoming'}, {name: 'Active'}, {name: 'Expired'}], []);
    const {width} = useWindowDimensions();
    const dimensions = useMemo(() => width <1200 ? ({width: '100%'}) : ({
        width: 'calc(25% - 8px)',
        ...S.mr2
    }), [width])
    return (
        <DView style={[S.flex, S.pt2, S.pb4, S.ph2]} key={initialIndex}>
            <ScrollView contentContainerStyle={S.flexGrow1} showsVerticalScrollIndicator={false}>
                {activeScreen === 0 ?
                    upcomingSubscriptions.length ?
                        <DView style={[S.row, S.wrap, S.flexStart]}>
                            {upcomingSubscriptions.map(sub => <DView style={dimensions} key={sub.id}>
                                <SubscriptionCard sub={sub}/>
                            </DView>)}
                        </DView>
                        : <Empty title={'No Upcoming Classes'}/>
                    : null}
                {activeScreen === 1 ?
                    activeSubscriptions.length ?
                        <DView style={[S.row, S.wrap, S.flexStart]}>
                            {activeSubscriptions.map(sub => <DView style={dimensions} key={sub.id}>
                                <SubscriptionCard key={sub.id} sub={sub}
                                                  onPress={() => navigate('InstructorClass', {subscriptionId: sub.id})}/>
                            </DView>)}
                        </DView>
                        : <Empty title={'No Active Classes'}/>
                    : null}
                {activeScreen === 2 ?
                    expiredSubscriptions.length ?
                        <DView style={[S.row, S.wrap, S.flexStart]}>
                            {expiredSubscriptions.map(sub => <DView style={dimensions} key={sub.id}>
                                <SubscriptionCard key={sub.id} sub={sub}/>
                            </DView>)}
                        </DView>
                        : <Empty title={'No Expired Classes'}/>
                    : null}
            </ScrollView>
            <DView style={[S.wFull, S.center, S.mt3, {position: 'absolute', bottom: 0, left: 0}]}>
                <Tabs tabs={tabs} activeScreen={activeScreen} onPress={setActiveScreen} bg={'inputBackround'}
                      contentContainerStyle={{width: '80%'}}/>
            </DView>
        </DView>
    );
};

export default InstructorClassesScreen;
