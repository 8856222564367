import {StyleSheet} from "react-native";
import Colors from "./Colors";
import Layout from "./Layout";

export const typographyStyles = StyleSheet.create({
    Huge: {fontSize: 48, fontWeight: "500", lineHeight: 60},
    H1: {fontSize: 36, fontWeight: "500", lineHeight: 44},
    H2: {fontSize: 24, fontWeight: "700", lineHeight: 32},
    H3: {fontSize: 20, fontWeight: "700", lineHeight: 24},
    H4: {fontSize: 16, fontWeight: "600", lineHeight: 20},

    P1: {fontSize: 16, fontWeight: "600", lineHeight: 20},
    P2: {fontSize: 16, fontWeight: "400", lineHeight: 20}, // Body/Field
    SubText: {fontSize: 14, fontWeight: "400", lineHeight: 20},
    Field: {fontSize: 16, fontWeight: "400", lineHeight: 20}, // Body/Body and Paragraph

    Button: {fontSize: 14, fontWeight: "600", lineHeight: 20}, // Buttons & tabs/Link
    Link: {fontSize: 14, fontWeight: "400", lineHeight: 20},

    PreText: {fontSize: 16, fontWeight: "700", lineHeight: 20},
    Notification: {fontSize: 14, fontWeight: "400", lineHeight: 20},
    Label: {fontSize: 12, fontWeight: "400", lineHeight: 16},
    notes: {fontSize: 14, fontWeight: "400", lineHeight: 20},
});
export const S = StyleSheet.create({
    flex: {flex: 1},
    flex2: {flex: 2},
    flex3: {flex: 3},
    flex4: {flex: 4},
    flex5: {flex: 5},
    flexGrow1: {flexGrow: 1},
    flexShrink1: {flexShrink: 1},
    flexWrap: {flexWrap: "wrap"},
    safeTop: {paddingTop: Layout.statusBarHeight},
    fill: {width: "100%", height: "100%"},
    row: {flexDirection: "row"},
    column: {flexDirection: "column"},
    rowReverse: {flexDirection: "row-reverse"},
    wrap: {flexWrap: "wrap"},
    start: {justifyContent: "flex-start"},
    end: {justifyContent: "flex-end"},
    between: {justifyContent: "space-between"},
    around: {justifyContent: "space-around"},
    evenly: {justifyContent: "space-evenly"},
    flexEnd: {alignItems: "flex-end"},
    flexStart: {alignItems: "flex-start"},
    center: {alignItems: "center"},
    selfCenter: {alignSelf: "center"},
    selfStart: {alignSelf: "flex-start"},
    selfEnd: {alignSelf: "flex-end"},
    justify: {justifyContent: "center"},
    p: {padding: 4},
    p1: {padding: 8},
    p2: {padding: 16},
    p3: {padding: 32},
    mReset: {marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0},
    m: {margin: 4},
    m1: {margin: 8},
    m2: {margin: 16},
    m3: {margin: 32},
    pReset: {paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0},
    pt0: {paddingTop: 4},
    pt1: {paddingTop: 8},
    pt2: {paddingTop: 16},
    pt3: {paddingTop: 32},
    pr0: {paddingRight: 4},
    pr1: {paddingRight: 8},
    pr2: {paddingRight: 16},
    pr3: {paddingRight: 32},
    pb0: {paddingBottom: 4},
    pb1: {paddingBottom: 8},
    pb2: {paddingBottom: 16},
    pb3: {paddingBottom: 32},
    pb4: {paddingBottom: 64},
    pl0: {paddingLeft: 4},
    pl1: {paddingLeft: 8},
    pl2: {paddingLeft: 16},
    pl3: {paddingLeft: 32},
    ph0: {paddingHorizontal: 4},
    ph1: {paddingHorizontal: 8},
    ph2: {paddingHorizontal: 16},
    ph3: {paddingHorizontal: 32},
    pv0: {paddingVertical: 4},
    pv1: {paddingVertical: 8},
    pv2: {paddingVertical: 16},
    pv3: {paddingVertical: 32},
    mh0: {marginHorizontal: 4},
    mh1: {marginHorizontal: 8},
    mh2: {marginHorizontal: 16},
    mh3: {marginHorizontal: 32},
    mv0: {marginVertical: 4},
    mv1: {marginVertical: 8},
    mv2: {marginVertical: 16},
    mv3: {marginVertical: 32},
    mr0: {marginRight: 4},
    mr1: {marginRight: 8},
    mr2: {marginRight: 16},
    mr3: {marginRight: 32},
    ml0: {marginLeft: 4},
    ml1: {marginLeft: 8},
    ml2: {marginLeft: 16},
    ml3: {marginLeft: 32},
    mb0: {marginBottom: 4},
    mb1: {marginBottom: 8},
    mb2: {marginBottom: 16},
    mb3: {marginBottom: 32},
    mt0: {marginTop: 4},
    mt1: {marginTop: 8},
    mt2: {marginTop: 16},
    mt3: {marginTop: 32},
    wFull: {width: "100%"},
    w75: {width: "75%"},
    w25: {width: "25%"},
    w31: {width: "31%"},
    w50: {width: "50%"},
    hFull: {height: "100%"},
    h54: {height: 54},
    overflowHidden: {overflow: "hidden"},
    rounded50: {
        overflow: "hidden",
        borderRadius: 50,
    },

    rowAround: {
        flexDirection: "row",
        justifyContent: "space-around",
    },
    divider: {
        height: StyleSheet.hairlineWidth,
        backgroundColor: "transparent",
        marginVertical: 12,
        width: "100%",
    },
    textCenter: {textAlign: "center"},
    textRight: {textAlign: "right"},
    textLeft: {textAlign: "left"},
    textJustify: {textAlign: "justify"},
    textVCenter: {textAlignVertical: "center"},
    textVTop: {textAlignVertical: "top"},
    textVBottom: {textAlignVertical: "bottom"},
    modalHeader: {textAlign: "center", fontSize: 22, fontWeight: "500"},
    subsection: {
        fontSize: 14,
        fontWeight: "500",
        lineHeight: 22,
    },
    sectionTitle: {
        textAlign: "center",
        width: "100%",
        fontWeight: "900",
        fontSize: 26,
        marginTop: 24,
        marginBottom: 24,
    },
    symbolTitle: {
        // fontFamily: 'SFProText',
        fontSize: 17,
        fontWeight: "600",
        fontStyle: "normal",
        lineHeight: 22,
        letterSpacing: -0.41,
    },
    shadow: {
        shadowColor: Colors.dark.darkGrey,
        shadowRadius: 3
    },
    shadowXl: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.4,
        shadowRadius: 6,

        elevation: 10,
    },
    darkOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.6)",
    },
    backdrop: {backgroundColor: "rgba(0,0,0,0.7)"},
    transparentBackground: {backgroundColor: "transparent"},
    radiusTop: {borderTopLeftRadius: 20, borderTopRightRadius: 20},
    radius0: {borderRadius: 6},
    radius1: {borderRadius: 12},
    radius2: {borderRadius: 18},
    radiusLeft0: {
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
    },
    radiusLeft1: {
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
    },
    radiusLeft2: {
        borderTopLeftRadius: 18,
        borderBottomLeftRadius: 18,
    },
    radiusRight0: {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
    },
    radiusRight1: {
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
    },
    radiusRight2: {
        borderTopRightRadius: 18,
        borderBottomRightRadius: 18,
    },
    radiusBottom0: {
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6
    },
    radiusBottom1: {
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12
    },
    radiusBottom2: {
        borderBottomLeftRadius: 18,
        borderBottomRightRadius: 18
    },
    absoluteBottomCenter: {position: "absolute", bottom: 10, left: 0, right: 0},
    cardBorder: {
        borderWidth: 1,
        borderColor: Colors.dark.lightGrey,
    },
    cardBorderTop: {
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: Colors.dark.lightGrey,
    },
    elevation: {
        elevation: 2,
        shadowColor: Colors.dark.darkGrey,
        shadowRadius: 2
    },
    ...typographyStyles,
});
