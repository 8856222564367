import React, { useState } from "react";
import { ActivityIndicator, ScrollView, View as DView } from 'react-native';
import { Calendar } from "react-native-calendars";
import { MarkedDates } from "react-native-calendars/src/types";
import { ISubscription } from "../../api/types";
import ClassCard from "../../components/Classes/ClassCard";
import Subscription from "../../components/Classes/Subscription";
import Icon from "../../components/Icon";
import { PaymentsModal } from "../../components/Payments/PaymentsModal";
import { Text } from "../../components/Themed";
import { S } from "../../constants/Styles";
import { useClasses } from "../../hooks/queries/useClasses";
import { useClassesPhotos } from "../../hooks/queries/useClassesPhotos";
import { UseClassSubscriptions } from "../../hooks/queries/useClassSubscriptions";
import { useColors } from "../../hooks/ThemeContext";
import { RootTabScreenProps } from "../../types.web";

const CalendarScreen = ({route}: RootTabScreenProps<"CalendarScreen">) => {
    const params = route.params;
    const colors = useColors();
    const [subscriptionModal, setSubscriptionModal] = useState<ISubscription | null>(null);

    const {data: photos} = useClassesPhotos();
    const {data: classes} = useClasses();

    const [date, setDate] = useState('1970-01-01');
    const {subscriptions, loading} = UseClassSubscriptions(params?.classId + "");

    const onSubscription = (sub: ISubscription) => {
        setSubscriptionModal(sub);
    }
    const c = classes.find(c => c.id == params?.classId)

    if (loading) {
        return <ActivityIndicator color={colors.accent}/>;
    }

    if (!params?.classId || !c || !subscriptions?.length)
        return (
            <DView style={[S.flex, S.center, S.justify]}>
                <Text variant="H1">Class is temporarily disabled</Text>
            </DView>
        );

    const markedDates = subscriptions?.reduce((acc, val) => {
        const markedDates = {[val.dateFrom]: {disabled: false, marked: true, dotColor: colors.accent, selected: true, selectedColor: colors.accent}} as MarkedDates;
        return {...acc, ...markedDates}
    }, {})

    return (
        <DView style={[S.flex, {position: 'relative'}]}>
            <ScrollView contentContainerStyle={S.flexGrow1} showsVerticalScrollIndicator={false}>
                <DView>
                    <ClassCard showDescription
                               c={c} 
                               titleStyle={[S.Huge, {position: "relative", color: colors.accent}]}
                               descriptionStyle={[{position: "relative", color: colors.accent}, S.SubText, S.ph0, S.mb2]}
                               containerStyle={[S.center, S.end, {
                                   overflow: "hidden",
                                   minHeight: 100,
                                   maxHeight: 100,
                                   backgroundColor: 'transparent'
                               }]}/>
                    <Calendar
                        key={params.classId}
                        theme={{
                            calendarBackground: colors.transparent,
                            dayTextColor: colors.accent,
                            textDisabledColor: colors.darkGrey,
                            todayBackgroundColor: colors.accent2,
                            todayTextColor: colors.white,
                            arrowColor: colors.accent,
                            monthTextColor: colors.accent,
                            textMonthFontWeight: 'bold'
                        }}
                        renderArrow={(direction) => direction === 'left' ?
                            <Icon name={'arrow-back-outline'} color={'accent'}/> :
                            <Icon name={'arrow-forward-outline'} color={'accent'}/>}
                        date={date}
                        onDayPress={(e) => setDate(e.dateString)}
                        pastScrollRange={0}
                        futureScrollRange={12}
                        // onDayPress={chooseDate}
                        markedDates={markedDates}
                        disabledByDefault
                        enableSwipeMonths={false}
                        scrollEnabled={false}
                        animateScroll={false}
                        disableAllTouchEventsForDisabledDays
                        calendarHeight={100}
                        hideExtraDays
                    />
                </DView>
                <DView style={[S.ph2, S.mt3,]}>
                    <Subscription selectedDate={date} allAvailableDates={subscriptions}
                                  onPress={onSubscription}/>
                </DView>
            </ScrollView>
            <PaymentsModal subscription={subscriptionModal}
                           onClose={() => setSubscriptionModal(null)}/>
        </DView>
    );
};

export default CalendarScreen;
