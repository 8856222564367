import { useQuery } from "@tanstack/react-query";
import { getDailySubscriptions } from "../../api/client";
import { IDailySubscription } from "../../api/types";
import { queryClient } from "../QueryProvider";

export const useDailySubs = (subIds: string, enabled: boolean) => {

    const invalidate = () => queryClient.invalidateQueries(['absentUsers'])

    const query = useQuery({
        queryKey: ["dailySubs", subIds],
        queryFn: () => getDailySubscriptions(subIds),
        enabled,
        // suspense: true,
    });

    let data = [] as IDailySubscription[];
    if (!query.isLoading && query?.data) data = query?.data;

    return {
        data,
        invalidate,
        loading: query?.isLoading,
    };
};
