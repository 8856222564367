import React from 'react';
import {Pressable, StyleProp, ViewStyle} from 'react-native';
import {S} from '../constants/Styles';
import {ColorType} from '../hooks/ThemeContext';
import {Text, View} from './Themed';

export type ITab = { name: string }

export interface ITabs {
    tabs: ITab[];
    activeScreen: number,
    bg?: ColorType,
    onPress?: (index: number) => void,
    contentContainerStyle?: StyleProp<ViewStyle>
}

const Tabs = ((props: ITabs) => {
    return (
        <View bg={props.bg}
              style={[S.row, S.radius2, S.p1, S.overflowHidden, S.elevation, props.contentContainerStyle]}>{props.tabs.map((tab, index) =>
            <Pressable
                key={tab.name} onPress={() => props?.onPress?.(index)} style={[S.flex]}>
                <Text bg={index === props.activeScreen ? 'accent' : 'transparent'}
                      color={index === props.activeScreen ? 'background' : 'accent'}
                      style={[S.pv1, S.textCenter, S.radius1]}>{tab.name}</Text>
            </Pressable>)}</View>
    )
});

export default Tabs
