import React, { useMemo, useState } from "react";
import { ActivityIndicator, ScrollView, View as DView } from 'react-native';
import { Calendar } from "react-native-calendars";
import { MarkedDates } from "react-native-calendars/src/types";
import Subscription from "../../components/Classes/Subscription";
import Icon from "../../components/Icon";
import { S } from "../../constants/Styles";
import { useDailySubs } from "../../hooks/queries/useDailySubs";
import { useUserSubscriptions } from "../../hooks/queries/useUserSubscriptions";
import { useColors } from "../../hooks/ThemeContext";

const UserSchedule = () => {
    const colors = useColors();

    const [date, setDate] = useState('1970-01-01');
    const {activeSubscriptions, upcomingSubscriptions, loading} = useUserSubscriptions();
    const userSubscriptions = activeSubscriptions.concat(upcomingSubscriptions);
    const subscriptions = useMemo(() => userSubscriptions.map(s => s.subscription), [userSubscriptions]);
    const {data: dailies, loading: loadingDailies} = useDailySubs(userSubscriptions.map(s => s.subscription.id).join('-'), !!userSubscriptions?.length);

    if (loading || loadingDailies) {
        return <ActivityIndicator color={colors.accent}/>;
    }

    const markedDates = dailies?.reduce((acc, val) => {
        const markedDates = {[val.date]: {disabled: false, selected: true, selectedColor: colors.accent2}} as MarkedDates;
        return {...acc, ...markedDates}
    }, {})

    return (
        <DView style={[S.flex, {position: 'relative'}]}>
            <ScrollView contentContainerStyle={S.flexGrow1} showsVerticalScrollIndicator={false}>
                <DView>
                    <Calendar
                        theme={{
                            calendarBackground: colors.transparent,
                            dayTextColor: colors.accent,
                            textDisabledColor: colors.darkGrey,
                            todayBackgroundColor: colors.transparent,
                            todayTextColor: colors.darkGrey,
                            arrowColor: colors.accent,
                            monthTextColor: colors.accent,
                            textMonthFontWeight: 'bold'
                        }}
                        renderArrow={(direction) => direction === 'left' ?
                            <Icon name={'arrow-back-outline'} color={'accent'}/> :
                            <Icon name={'arrow-forward-outline'} color={'accent'}/>}
                        date={date}
                        onDayPress={(e) => setDate(e.dateString)}
                        pastScrollRange={0}
                        futureScrollRange={12}
                        // onDayPress={chooseDate}
                        markedDates={markedDates}
                        disabledByDefault
                        enableSwipeMonths={false}
                        scrollEnabled={false}
                        animateScroll={false}
                        disableAllTouchEventsForDisabledDays
                        calendarHeight={100}
                        hideExtraDays
                    />
                </DView>
                <DView style={[S.ph2, S.mt3,]}>
                    <Subscription selectedDate={date} allAvailableDates={subscriptions} isSchedule/>
                </DView>
            </ScrollView>
        </DView>
    );
};

export default UserSchedule;
