import AsyncStorage from "@react-native-async-storage/async-storage";
import {api} from "../../api/api";
import {IUserInfo} from "../../api/types";
import {navigateAndSimpleReset} from "../../navigation/helpers";
import {userStore} from "./UserStore";

export const bootstrap = async ({data}: { data: { accessToken: string; refreshToken: string } }) => {
    const {accessToken, refreshToken} = data;
    await AsyncStorage.setItem("accessToken", accessToken);
    if (refreshToken) await AsyncStorage.setItem("refreshToken", refreshToken);
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const userInfo = await api.get<IUserInfo>("users").catch(console.error);

    if (!userInfo?.data) return;

    userStore.update({accessToken, refreshToken, ...userInfo.data});
    navigateAndSimpleReset("Root");
};
