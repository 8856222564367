import { useQuery } from "@tanstack/react-query"
import { getUserSubscriptions } from "../../api/client"
import { IUserSubscription } from "../../api/types"
import { isBetween, isDateAfter, isDateBefore } from "../../helpers/helpers"
import { userStore } from "../../stores/userStore/UserStore"
import { queryClient } from "../QueryProvider"

export const useUserSubscriptions = () => {
    const queryKey = ['userSubscriptions']
    const invalidate = () => queryClient.invalidateQueries(queryKey)

    const query = useQuery({
        queryFn: () => getUserSubscriptions(),
        queryKey,
        // suspense: true,
        staleTime: 0,
        enabled: userStore.role === 'user'
    })

    let activeSubscriptions = [] as IUserSubscription[];
    let upcomingSubscriptions = [] as IUserSubscription[];
    let expiredSubscriptions = [] as IUserSubscription[];


    if (query.isSuccess && query?.data?.length > 0) {
        const userSubscriptions = query.data;
        activeSubscriptions = userSubscriptions.filter((userSubscription) => isBetween(userSubscription.subscription.dateFrom, userSubscription.subscription.dateTo))
        upcomingSubscriptions = userSubscriptions.filter((userSubscription) => isDateAfter(userSubscription.subscription.dateFrom))
        expiredSubscriptions = userSubscriptions.filter((userSubscription) => isDateBefore(userSubscription.subscription.dateTo))
    }

    return {
        loading: query.isLoading,
        invalidate,
        activeSubscriptions,
        upcomingSubscriptions,
        expiredSubscriptions
    }
}
