import React, { useCallback } from "react";
import { Button, Text, View } from "../components/Themed";
import { S } from "../constants/Styles";
import { useColors } from "../hooks/ThemeContext";
import { useMediaQuery } from "../hooks/useMediaQuery";

const SettingsScreen = () => {
    const colors = useColors();
    const { isWeb } = useMediaQuery()
    const deleteAccount = useCallback(
        () => { },
        [],
    )

    return (
        <View style={[S.flex]}>
            <View bg={isWeb ? 'transparent' : 'background'} style={[S.radius2, S.p2, S.m1, S.elevation]}>
                <Button buttonStyle={{ backgroundColor: colors.accent }}
                    titleStyle={{ color: colors.white }} style={[{ width: 200 }, S.mb1]}>Close Account</Button>
                <Text>Closing your account with Befit Ladies Gym will remove all your data and cancel all your subscriptions immediately.</Text>
            </View>
        </View>
    );
};

export default SettingsScreen;
