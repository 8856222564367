import React, { ReactNode } from 'react';
import { Platform, Pressable, StyleProp, View as DView, ViewStyle } from 'react-native';
import { S } from '../../constants/Styles';
import Icon, { IconProps } from '../Icon';
import { View } from '../Themed';

interface Props {
    iconProps: IconProps;
    top: ReactNode;
    subText?: ReactNode;
    bottom: ReactNode | ReactNode[];
    right?: ReactNode;
    topContainerStyle?: StyleProp<ViewStyle>;
    bottomContainerStyle?: StyleProp<ViewStyle>;
    rightContainerStyle?: StyleProp<ViewStyle>;
    contentContainerStyle?: StyleProp<ViewStyle>;
    onPress?: () => void;
}

const HomeCard = ({
                      top,
                      subText,
                      bottom,
                      right,
                      iconProps,
                      topContainerStyle,
                      bottomContainerStyle,
                      rightContainerStyle,
                      contentContainerStyle,
                      onPress
                  }: Props) => {
    const shadow = Platform.OS === 'web' ?
        S.shadow
        : S.elevation;
    return (
        <View bg={'inputBackround'} style={[S.p2, S.radius2, shadow, contentContainerStyle]}>
            <Pressable onPress={onPress}>
                <DView style={[S.row, S.center, topContainerStyle]}>
                    {iconProps ? <Icon {...iconProps} /> : null}
                    <DView style={[S.ml0, S.wrap, S.wFull, S.flex]}>
                        {top}
                    </DView>
                </DView>
                <DView style={[S.pt2, S.row]}>
                    <DView style={[S.flex, bottomContainerStyle]}>
                        {subText ? <DView style={S.mt0}>{subText}</DView> : null}
                        {bottom}
                    </DView>
                    {right ? <DView style={rightContainerStyle}>{right}</DView> : null}
                </DView>
            </Pressable>
        </View>

    )
}

export default HomeCard
