import { Platform, useWindowDimensions } from "react-native";

export const useMediaQuery = () => {

    const {width} = useWindowDimensions();

    return {
        isWeb: Platform.OS === 'web',
        lg: width >= 1000,
        md: width > 300 && width < 1000,
        sm: width <= 300
    }
}