import * as Linking from 'expo-linking';

const linking = {
    enabled: true,
    prefixes: [Linking.createURL('http://localhost:19006/'), 'befit://'],
    config: {
        screens: {
            Root: {
                screens: {
                    Classes: 'classes',
                    Subscriptions: 'subscriptions',
                    InstructorClasses: 'instructor-classes',
                    UserProfile: 'user-profile',
                    InstructorClass: 'instructor-class/:subscriptionId',
                    CalendarScreen: 'calendar/:classId',
                    Settings: 'user-settings',
                    InstructorSchedule: 'instructor-schedule',
                    UserSchedule: 'my-schedule',
                }
            },
            ResetPasswordScreen: 'reset-password/:accessToken',
            ForgotPasswordScreen: 'forgot-password',
            MainScreen: 'main',
            SignInScreen: 'sign-in',
            SignUpScreen: 'sign-up'
        },
    }
};

export default linking;
