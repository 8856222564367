// import 'react-native-gesture-handler';
import { AppRegistry } from 'react-native';
import App from './App';

import Ionicons from '/node_modules/react-native-vector-icons/Fonts/Ionicons.ttf';

const IoniconsStyles = `@font-face {
  src: url(${Ionicons});
  font-family: Ionicons;
}`;

const style = document.createElement('style');
style.type = 'text/css';

if (style.styleSheet) {
  style.styleSheet.cssText = IoniconsStyles;
} else {
  style.appendChild(document.createTextNode(IoniconsStyles));
}

document.head.appendChild(style);

const style2 = document.createElement('style');

style2.appendChild(document.createTextNode(`#root {background: radial-gradient(at center center, #3C0A64 0%, #0B0B0B 100%)}`))
document.head.appendChild(style2);

if (module.hot) {
    module.hot.accept();
}
console.log('STARING WEB');
AppRegistry.registerComponent('Befit', () => App);
AppRegistry.runApplication('Befit', {
    rootTag: document.getElementById('root'),
});
