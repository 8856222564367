import {useQuery} from "@tanstack/react-query"
import {getStripeClientSecret} from "../../api/client"
import {queryClient} from "../QueryProvider"

export function UseStripe(subscriptionId: string) {
    const invalidate = () => queryClient.invalidateQueries(['stripeClientSecret'])

    const query = useQuery({
        queryKey: ['stripeClientSecret'],
        queryFn: () => getStripeClientSecret(subscriptionId),
        staleTime: 0,
        cacheTime: 0,
    })

    let data = '';
    if (query.isSuccess && query.data) {
        data = query.data.data.clientSecret;
    }

    return {
        stripeClientSecret: data,
        loading: query.isLoading,
        invalidate
    }
}
