import { SafeAreaProvider } from "react-native-safe-area-context";

import useCachedResources from "./src/hooks/useCachedResources";

import { RootSiblingParent } from "react-native-root-siblings";
import StatusBar from "./src/components/StatusBar";
import { QueryClientProvider } from "./src/hooks/QueryProvider";
import Navigation from "./src/navigation/index.web";

export default function App() {
    const isLoadingComplete = useCachedResources();

    if (!isLoadingComplete) {
        return null;
    } else {
        return (
            <RootSiblingParent>
                <SafeAreaProvider>
                    {/*<ThemeContext.Provider value={themeData}>*/}
                    <QueryClientProvider>
                        <Navigation/>
                    </QueryClientProvider>
                    <StatusBar/>
                    {/*</ThemeContext.Provider>*/}
                </SafeAreaProvider>
            </RootSiblingParent>

        );
    }
}
