// @flow
import { FAB } from "@rneui/themed";
import * as React from 'react';
import { useState } from 'react';
import { View as DView } from 'react-native';
import { S } from "../../../constants/Styles";
import { useColors, useTheme } from "../../../hooks/ThemeContext";
import { Input } from "../../EditableInput";
import Icon from "../../Icon";

type Props = {
    handleChip: (value: string) => void;
    title: string
};

const ChipField = (props: Props) => {
    const [localState, setLocalState] = useState("");
    const {theme} = useTheme();
    const sanitizedState = localState.replace(/[^0-9A-Za-z' ]/, '').replace(/  +/g, ' ')
    const colors = useColors();
    const save = () => {
        if (!sanitizedState.trim()) return;
        props.handleChip(sanitizedState.trim())
        setLocalState("");
    }
    console.log(theme)
    return (
        <DView style={[S.row, S.wFull, S.flexEnd]}>
            <Input color={'accent'}
                value={sanitizedState}
                onChangeText={setLocalState}
                onSubmitEditing={save}
                bg="inputBackround"
                mode={'flat'}
                placeholder={props.title}
                style={[S.wFull, S.flexGrow1]}
                containerStyle={S.flexGrow1}
            />
            <DView style={{ position: 'absolute', right: S.ph2.paddingHorizontal, bottom: 20 }}>
                <FAB
                    icon={<Icon name={'add-outline'} color={'background'} size={15} />}
                    containerStyle={[S.center, S.justify, { width: 25, aspectRatio: 1 }]}
                    iconContainerStyle={[S.center, S.justify]}
                    buttonStyle={{ backgroundColor: colors.accent }}
                    onPress={save}
                />
            </DView>
        </DView>
    );
};

export default ChipField;
