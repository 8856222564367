import {IconNode} from "@rneui/base";
import React from "react";
import {StyleProp, TextStyle, View as DView, ViewStyle} from "react-native";
import {S} from "../constants/Styles";
import {useColors} from "../hooks/ThemeContext";
import ChipField from "./Home/Instructor/ChipField";
import {Chip} from "@rneui/themed";
import Icon from "./Icon";

const Chips = ({
                   title,
                   titleStyle,
                   data,
                   icon,
                   chipStyle,
                   containerStyle,
                   onPress,
                   handleChip,
               }: {
    title: string; titleStyle?: StyleProp<TextStyle>; data: string[]; icon?: IconNode; onPress?: (index: number) => void; handleChip:
        (value: string) => void, chipStyle?: StyleProp<ViewStyle>; containerStyle?: StyleProp<ViewStyle>
}) => {
    const colors = useColors();
    return (
        <DView style={[S.flexStart, S.mb1, S.radius2, containerStyle]}>
            <ChipField handleChip={handleChip} title={title}/>
            {data.length ? <DView style={[S.row, S.wrap, S.p2]}>
                {data?.map((item, index) => (
                    <DView style={[S.mb1, S.mr1]} key={index} collapsable={false}>
                        <Chip title={item} icon={icon ? icon :
                            <Icon name="close" size={20} color={"xColor"}/>}
                              iconRight
                              type="outline"
                              buttonStyle={{borderWidth: 1, borderColor: colors.accent}} style={[chipStyle]}
                              titleStyle={{color: colors.accent, fontSize: 10}}
                              onPress={() => onPress?.(index)}
                        />
                    </DView>
                ))}
            </DView> : null}
        </DView>
    );
};

export default Chips;
