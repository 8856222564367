import * as React from 'react';
import {ISubscription} from "../../api/types";
import {Text, View} from "../Themed";
import {S} from '../../constants/Styles';

type Props = {
    subscription: ISubscription,
    invert?: boolean
};

export function SubscriptionSummary({invert, subscription}: Props) {
    return (
        <View bg={invert ? 'accent' : 'white'} style={[S.p2, S.radius2, S.elevation]}>
            <Text color={invert ? 'white' : 'accent'} variant={"H1"}>{subscription.class.name}</Text>
            <Text color={invert ? 'white' : 'accent'} variant={"P1"}>{subscription.instructor.name}</Text>
            <Text color={invert ? 'white' : 'accent'}
                  variant={"SubText"}>{subscription.dateFrom} - {subscription.dateTo}</Text>
            <Text color={invert ? 'white' : 'accent'}
                  variant={"SubText"}>{subscription.timeFrom} - {subscription.timeTo}</Text>
            <Text color={invert ? 'white' : 'accent'}
                  variant={"SubText"}>{subscription.price} {subscription.priceUnit}</Text>
        </View>
    );
}
