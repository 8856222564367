import { StatusBar as DStatusBar } from "expo-status-bar";
import React from "react";
import { useColors, useTheme } from "../hooks/ThemeContext";

const StatusBar = () => {
    const colors = useColors();
    const {theme} = useTheme();
    return <DStatusBar backgroundColor={colors.background} style={'dark'}/>;
};

export default StatusBar;
