// @flow
import * as React from 'react';
import {useState} from 'react';
import {AuthStackScreenProps} from "../../../types";
import {Button, View} from "../../../components/Themed";
import {Input} from "../../../components/EditableInput";
import {api} from "../../../api/api";
import {showToast} from "../../../helpers/helpers";
import {navigate} from "../../../navigation/helpers";
// @ts-ignore
import img from '../../../../assets/images/main.jpg';
import {Image, View as DView} from "react-native";
import {S} from "../../../constants/Styles";
import {Logo} from "../../../components/Icons/Icons";
import {useColors} from "../../../hooks/ThemeContext";
import {WebWrapper} from "../../../components/WebWrapper";

export function ResetPasswordScreen({route}: AuthStackScreenProps<'ResetPasswordScreen'>) {
    if (!route.params?.accessToken) return null;

    const [password, setPassword] = useState("");
    const [repeatedPassword, setRepeatPassword] = useState("");

    const token = route.params?.accessToken;
    const resetPassword = () => {
        if (password === repeatedPassword)
            return api.post(`auth/reset-password`, {password}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((data) => {
                console.log(data.data)
                navigate('SignInScreen')
            });
        else
            showToast("Passwords are not matching")
    }

    const colors = useColors();

    return (
        <View style={[S.flex]} bg={"accent"}>
            <Image style={[S.wFull, S.hFull, {position: 'absolute', top: 0, right: 0, left: 0}]}
                   source={{uri: img}}/>
            <WebWrapper>
                <DView style={[S.flex, S.end, {bottom: 100}]}>
                    <DView style={[S.center, S.mb3]}>
                        <Logo size={200}/>
                    </DView>
                    <Input placeholder={'New Password'} onChangeText={setPassword}
                           containerStyle={S.mb2} value={password}/>
                    <Input placeholder={'Repeat New Password'} onChangeText={setRepeatPassword}
                           containerStyle={S.mb3} value={repeatedPassword}/>
                    <Button buttonStyle={{backgroundColor: colors.accent}} titleStyle={{color: colors.white}}
                            title={'Reset Password'} onPress={resetPassword}/>
                </DView>
            </WebWrapper>
        </View>
    );
}
