// @flow
import { useQuery } from "@tanstack/react-query";
import { getSubscriptionsByClass } from "../../api/client";
import { ISubscription } from "../../api/types";
import { queryClient } from "../QueryProvider";


export function UseClassSubscriptions(id: string) {
    const query = useQuery({
        queryKey: ['classSubscriptions', id],
        queryFn: () => getSubscriptionsByClass(id),
        cacheTime: 0,
        networkMode: 'offlineFirst',
        enabled: !!id,
    });
    const invalidate = () => queryClient.invalidateQueries([`classSubscriptions-${id}`])

    let subscriptions = [] as ISubscription[]
    if (query.isSuccess && query?.data?.length) {
        subscriptions = query.data;
    }

    return {
        subscriptions,
        invalidate,
        loading: query.isLoading
    }
}
