import { forwardRef, useEffect, useRef, useState } from "react";
import { Animated, Easing, StyleProp, StyleSheet, TextInput, TextInputProps, ViewStyle } from "react-native";
import { S } from "../constants/Styles";
import { ColorType, useColors } from "../hooks/ThemeContext";
import { View } from "./Themed";

export type TextFieldVariant = "focus" | "error" | "warning" | "success" | "default";

export interface TextFieldProps extends TextInputProps {
    color?: ColorType;
    placeholderTextColor?: ColorType;
    mode?: "flat" | "outlined";
    icon?: string;
    label?: string;
    labelColor?: ColorType;
    text?: string;
    textColor?: ColorType;
    bg?: ColorType;
    disabled?: boolean;
    variant?: TextFieldVariant;
    onChangeText?: (text: string) => void;
    containerStyle?: StyleProp<ViewStyle>
}

const borderColor = (variant: TextFieldVariant) => {
    let color: ColorType = "darkGrey";
    switch (variant) {
        case "focus":
            color = "accent";
            break;
        case "error":
            color = "brightRed";
            break;
        case "warning":
            color = "brightYellow";
            break;
        case "success":
            color = "lightGreen";
            break;
    }
    return color;
};

export const Input = forwardRef<TextInput, TextFieldProps>((props, ref) => {
    const {
        mode = 'flat',
        style,
        placeholderTextColor,
        variant = "default",
        onFocus,
        onBlur,
        containerStyle,
        ...otherProps
    } = props;
    const colors = useColors();
    const backgroundColor: ColorType = "inputBackround";
    const [isFocused, setIsFocused] = useState(otherProps.autoFocus || false);
    const hasValue = !!otherProps?.value;

    const focusAnim = useRef(new Animated.Value(otherProps?.value ? 1 : 0)).current;

    useEffect(() => {
        Animated.timing(focusAnim, {
            toValue: isFocused || hasValue ? 1 : 0,
            duration: 300,
            easing: Easing.bezier(0.4, 0, 0.2, 1),
            useNativeDriver: false,
        }).start();
    }, [focusAnim, hasValue, isFocused]);

    const borders = mode === 'outlined' ? {
        borderColor: (!variant || variant === "default") && isFocused ? colors[borderColor("focus")] : colors[borderColor(variant || "default")],
        borderBottomWidth: 1,
    } : {
        borderRadius: S.radius1.borderRadius,
        paddingHorizontal: S.ph2.paddingHorizontal
    }

    const color = props.color && props.color in colors ? colors[props.color] : colors.accent;
    const placeholderColor = placeholderTextColor && placeholderTextColor in colors ? colors[placeholderTextColor] : colors.placeholderTextColor;
    return (
        <View
            style={[
                S.row,
                S.between,
                S.start,
                {
                    backgroundColor: colors[props.bg ? props.bg : backgroundColor],
                },
                borders,
                containerStyle,
            ]}
        >
            <TextInput
                onFocus={(e) => {
                    onFocus?.(e);
                    setIsFocused(true);
                }}
                onBlur={(e) => {
                    onBlur?.(e);
                    setIsFocused(false);
                }}
                ref={ref}
                placeholderTextColor={"transparent"}
                {...otherProps}
                style={[
                    S.wFull,
                    S.ph0,
                    S.pb1,
                    {paddingTop: 28},
                    {color},
                    // {backgroundColor},
                    {minHeight: 65},
                    {maxHeight: 150},
                    {zIndex: 1},
                    style,
                    //@ts-ignore
                    {outline: 'none'}
                ]}
            />
            {(props?.placeholder?.length || 0) < 200 ? (
                <Animated.View
                    style={[
                        S.wFull,
                        styles.labelContainer,
                        {
                            zIndex: 0,
                            // backgroundColor: outlinedBackgroundColor,
                            top: focusAnim.interpolate({
                                inputRange: [0, 1],
                                outputRange: [20, 8],
                            }),
                        },
                    ]}
                >
                    <Animated.Text
                        onPress={() => {
                            setIsFocused(true);
                        }}
                        style={[
                            S.textLeft,
                            {
                                fontSize: focusAnim.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [16, 12],
                                }),
                                color: placeholderColor,
                            },
                        ]}
                    >
                        {props.placeholder}
                    </Animated.Text>
                </Animated.View>
            ) : null}
        </View>
    );
});

const styles = StyleSheet.create({
    input: {
        borderColor: "#B9C4CA",
        borderWidth: 1,
        borderRadius: 4,
        fontFamily: "Avenir-Medium",
        fontSize: 16,
    },
    inputField: {
        borderColor: "transparent",
        borderWidth: 0,
    },
    labelContainer: {
        position: "absolute",
        left: S.ph2.paddingHorizontal,
        top: 0,
        backgroundColor: "transparent",
    },
});
