import { api } from "./api";
import { IClass, IClassPhoto, IDailySubscription, IInstructorSubscription, ISubscription, IUserAbsent, IUserAbsentRequest, IUserSubscription } from "./types";

export const getAllClasses = () => api.get<IClass[]>("classes").then(({ data }) => data);
export const getAllClassesPhotos = () => api.get<IClassPhoto[]>("classes/photos").then(({ data }) => data);
export const getClass = (id: string) => api.get<IClass>(`classes/${id}`).then(({ data }) => data);

export const getUserSubscriptions = () => api.get<IUserSubscription[]>("user-subscriptions").then(({ data }) => data);
export const getInstructorSubscriptions = () => api.get<IInstructorSubscription[]>("subscriptions/instructor").then(({ data }) => data);
export const getSubscriptionsByClass = (classId: string) => api.get<ISubscription[]>(`subscriptions/classId/${classId}`).then(({ data }) => data);

export const getAllSubscriptions = () => api.get<ISubscription[]>(`subscriptions`).then(({ data }) => data);
export const createSubscription = (subId: string) =>
	api
		.post(`user-subscriptions/`, {
			subId: String(subId),
		})
		.then(({ data }) => data);

export const getProfilePhoto = () => api.get("users/photo").then(({ data }) => data);

export const setUserAbsent = (data: IUserAbsentRequest) => api.post("user-attendance", data);

export const getAbsent = (userSubscriptionId: string) => api.get(`user-attendance/by-subscription/${userSubscriptionId}`).then(({ data }) => data);
export const getUserAbsent = () => api.get<IUserAbsent[]>(`user-attendance`).then(({ data }) => data);

export const getStripeClientSecret = (subscriptionId: string) =>
	api.post<{ clientSecret: string }>(
		`/payments/create-payment-intent`,
		{
			subscriptionId,
		},
		{
			headers: {
				"Content-Type": "application/json",
			},
		}
	);

export const getDailySubscriptions = (subIds: string) => api.get<IDailySubscription[]>(`/daily-subscription?subsIds=${subIds}`).then(({data}) => data);
