import { SearchBar } from "@rneui/base";
import React, { useState } from "react";
import { Pressable, useWindowDimensions, View as DView } from "react-native";
import ClassCard from "../../components/Classes/ClassCard";
import Icon from "../../components/Icon";
import { S } from "../../constants/Styles";
import { useClasses } from "../../hooks/queries/useClasses";
import { useClassesPhotos } from "../../hooks/queries/useClassesPhotos";
import { useColors } from "../../hooks/ThemeContext";
import { navigate } from "../../navigation/helpers.web";

const ClassesScreen = () => {
    const [searchText, setSearchText] = useState("");
    const {data: classes} = useClasses();
    const {data: photos} = useClassesPhotos();
    const colors = useColors();
    const filteredData = searchText.trim().length > 0 ? classes.filter((c) => c.name.toLowerCase().includes(searchText.toLowerCase())) : classes;
    const {width} = useWindowDimensions();
    let grid = { gridTemplateColumns: 'repeat(3, 1fr)' };
    if (width < 1200 && width >= 400) {
        grid = { gridTemplateColumns: 'repeat(2, 1fr)' };
    } else if (width < 400) {
        grid = { gridTemplateColumns: 'repeat(1, 1fr)' }
    }
    return (
        <DView style={[S.flex, S.ph2, S.pb4, S.pt1, S.center]}>
            <SearchBar
                searchIcon={<Icon name={'search-outline'} color={'lightGrey'}/>}
                clearIcon={<Icon name={'close-outline'} color={'lightGrey'} onPress={() => setSearchText('')}/>}
                containerStyle={[S.mb3, {backgroundColor: colors.inputBackround}, S.wFull, {
                    borderTopWidth: 0,
                    borderBottomWidth: 0,
                    borderRadius: 12,
                }, S.elevation, S.shadow]}
                inputContainerStyle={[{backgroundColor: colors.inputBackround, height: 30}, S.pReset]}
                // @ts-ignore
                inputStyle={{outline: 'none', color: colors.accent}}
                value={searchText}
                onChangeText={setSearchText}
                placeholder={"Search..."}
                clearButtonMode="unless-editing"
            />
            <DView style={[S.wFull]}>
                <div style={{display: 'grid', gap: 16, ...grid}}>
                    {filteredData.map((item, index) =>
                        (
                            <Pressable key={item.id}
                                       style={[{aspectRatio: 1.75}]}
                                       onPress={() => navigate("CalendarScreen", {classId: item.id})}>
                                <ClassCard c={item} image={photos?.find((photo) => photo.id === item.id)}
                                           titleStyle={[S.H2, {position: "absolute", bottom: 30, left: 30}]}
                                           containerStyle={[S.wFull, S.flex, S.radius2, S.shadowXl, {overflow: 'hidden'}]}/>
                            </Pressable>
                        )
                    )}
                </div>
            </DView>
        </DView>
    );
};

export default ClassesScreen;
