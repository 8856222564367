import { Dimensions, Platform, StatusBar } from "react-native";
import { initialWindowMetrics } from "react-native-safe-area-context";

const width = Dimensions.get("window").width;
const height = Dimensions.get("window").height;

export const getDimensions = () => ({
	width: Number(initialWindowMetrics?.frame.width),
	height: Number(initialWindowMetrics?.frame.height),
});
export const StatusBarHeight = Platform.select({
	ios: 44,
	android: StatusBar.currentHeight,
	default: 20,
});

export default {
	statusBarHeight: StatusBarHeight,
	window: {
		width,
		height,
	},
	isSmallDevice: width < 375,
};
