import { useQuery } from "@tanstack/react-query"
import { getProfilePhoto } from "../../api/client"
import { queryClient } from "../QueryProvider"

export const useProfilePhoto = () => {
    const queryKey = ['profilePhoto']
    const invalidate = () => queryClient.invalidateQueries(queryKey)
    
    const query = useQuery({
		queryFn: () => getProfilePhoto(),
		queryKey,
        staleTime: 0,
	})

    return {
        query, 
        invalidate
    }
}