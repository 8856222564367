/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import {
    GestureResponderEvent,
    Platform,
    Text as DefaultText,
    TouchableHighlight as DefaultTouchableHighlight,
    View as DefaultView
} from "react-native";

import { Button as RneuiButton, ButtonProps as RneuiButtonProps } from "@rneui/themed";
import { useState } from "react";
import Colors from "../constants/Colors";
import { typographyStyles } from '../constants/Styles';
import { useColors, useTheme } from "../hooks/ThemeContext";
import { useMediaQuery } from "../hooks/useMediaQuery";

export function useThemeColor(props: { light?: string; dark?: string }, colorName: keyof typeof Colors.light & keyof typeof Colors.dark) {
    const {theme} = useTheme();
    const colorFromProps = props[theme];

    if (colorFromProps) {
        return colorFromProps;
    } else {
        return Colors[theme][colorName];
    }
}

type ThemeProps = {
    lightColor?: string;
    darkColor?: string;
    color?: keyof typeof Colors["dark"];
};

export type TouchableHighlightProps =
    ThemeProps
    & DefaultTouchableHighlight["props"]
    & { bg?: keyof typeof Colors["dark"]; underlayColor?: keyof typeof Colors["dark"] };
export type TextProps =
    ThemeProps
    & DefaultText["props"]
    & { variant?: keyof typeof typographyStyles, bg?: keyof typeof Colors["dark"] };
export type ViewProps = ThemeProps & DefaultView["props"] & { bg?: keyof typeof Colors["dark"], isScreen?: boolean };
export type ButtonProps = RneuiButtonProps & { bg?: keyof typeof Colors["dark"] };

export function Text(props: TextProps) {
    const {style, lightColor, darkColor, bg, ...otherProps} = props;
    const colors = useColors();
    const color = props.color ? colors[props.color] : useColors().accent;
    const variant = props.variant ? typographyStyles[props.variant] : null;
    const backgroundColor = props.bg ? colors[props.bg] : colors.transparent;

    return <DefaultText style={[{color}, {backgroundColor}, variant, style]} {...otherProps} />;
}

export function View(props: ViewProps) {
    const {style, lightColor, darkColor, isScreen, ...otherProps} = props;
    const colors = useColors();
    const {isWeb} = useMediaQuery();
    const backgroundColor = isScreen ? colors.background3 : props.bg ? colors[props.bg] : isWeb ? 'transparent' : colors.background2;

    return <DefaultView style={[{backgroundColor}, style]} {...otherProps} />;
}

export function Button(props: ButtonProps) {
    const {style, bg, onPress, ...otherProps} = props;
    const colors = useColors();
    const backgroundColor = bg ? colors[bg] : Platform.OS === 'web' ? undefined : colors.background;
    const [loading, setLoading] = useState(false);

    const handleOnPress = async (event: GestureResponderEvent) => {
        if (onPress) {
            const mightBePromise: any = onPress(event);
            if (mightBePromise && mightBePromise?.then) {
                setLoading(true);
                try {
                    await mightBePromise;
                } catch (err) {
                    console.log(err);
                }
                setLoading(false);
            }
        }
    };
    return <RneuiButton radius={12} style={[{backgroundColor}, style]} onPress={handleOnPress}
                        loading={loading} loadingProps={{color: colors.inputBackround}} {...otherProps} />;
}
