// @flow
import * as React from 'react';
import {useMemo} from 'react';
import {S} from "../constants/Styles";
import {Text, View} from "./Themed";
import {formatUIDate} from "../helpers/helpers";
import {ColorType, useColors} from "../hooks/ThemeContext";
import {Pressable, View as DView} from 'react-native'
import {IInstructorSubscription, ISubscription} from "../api/types";
import {dayArray} from "../constants/Constants";
import {userStore} from "../stores/userStore/UserStore";

export function SubscriptionCard({
                                     sub,
                                     variableColor,
                                     onPress
                                 }: { sub: ISubscription | IInstructorSubscription, variableColor?: ColorType, onPress?: () => void }) {
    const colors = useColors();
    const days = useMemo(() => sub.days.length === 7 ? 'Daily' : sub.days.map(day => dayArray[day]).join('-'), []);
    const color = variableColor ? variableColor : "accent";

    if (userStore.role === 'instructor') {
        const instructorSub = sub as IInstructorSubscription;
        return (
            <DView key={instructorSub.id} style={[S.wFull, S.mb2, S.selfStart, {paddingHorizontal: 1}]}>
                <Pressable style={[S.radius2, S.elevation, {backgroundColor: colors.inputBackround}]}
                           onPress={onPress}>
                    <View bg={color} style={[S.row, S.around, S.p2, S.radius2]}>
                        <Text color='background' variant='SubText'>{instructorSub.timeFrom}</Text>
                        <Text color='background' variant='SubText'>{instructorSub.timeTo}</Text>
                    </View>
                    <DView style={[S.p2, S.flex]}>
                        <Text color={variableColor} variant='H3'
                              style={S.mb1}>{instructorSub.class.name}</Text>
                        <Text color={variableColor} variant='P2'
                              style={S.mb1}>{instructorSub.instructor.name}</Text>
                        <Text color='xColor' variant='Label'>{days}</Text>
                        <Text color='xColor'
                              variant='Label'>{formatUIDate(instructorSub.dateFrom)} - {formatUIDate(instructorSub.dateTo)}</Text>
                    </DView>
                    <DView style={[S.row, S.p2, S.justify, S.center]}>
                        <Text color={color} variant='P2'
                              style={S.mr1}>{instructorSub.userSubscriptions.length === 1 ? instructorSub.userSubscriptions.length + ' student' : instructorSub.userSubscriptions.length + ' students'}</Text>
                    </DView>
                </Pressable>
            </DView>
        )
    }

    return (
        <DView key={sub.id} style={[S.wFull, S.mb2, S.selfStart, {paddingHorizontal: 1}]}>
            <Pressable style={[S.radius2, S.elevation, {backgroundColor: colors.inputBackround}]}
                       onPress={onPress}>
                <View bg={color} style={[S.row, S.around, S.p2, S.radius2]}>
                    <Text color='background' variant='SubText'>{sub.timeFrom}</Text>
                    <Text color='background' variant='SubText'>{sub.timeTo}</Text>
                </View>
                <DView style={[S.p2, S.flex]}>
                    <Text color={variableColor} variant='H3'
                          style={S.mb1}>{sub.class.name}</Text>
                    <Text color={variableColor} variant='P2'
                          style={S.mb1}>{sub.instructor.name}</Text>
                    <Text color='xColor' variant='Label'>{days}</Text>
                    <Text color='xColor'
                          variant='Label'>{formatUIDate(sub.dateFrom)} - {formatUIDate(sub.dateTo)}</Text>
                </DView>
                <DView style={[S.row, S.p2, S.justify, S.center]}>
                    <Text color={color} variant='P2' style={S.mr1}>{sub.price}</Text>
                    <Text color={color} variant='P1'>{sub.priceUnit}</Text>
                </DView>
            </Pressable>
        </DView>
    );
}
