import { format, parse } from 'date-fns';
import { isEqual } from 'lodash';
import React, { memo, useMemo } from 'react';
import { Calendar } from 'react-native-calendars';
import { MarkedDates } from 'react-native-calendars/src/types';
import { IInstructorSubscription } from '../../api/types';
import Icon from '../../components/Icon';
import { dateFormat } from '../../helpers/helpers';
import { useDailySubs } from '../../hooks/queries/useDailySubs';
import { useColors } from '../../hooks/ThemeContext';

const ScheduleContainer = memo(({ subscriptions, setSubsToShow }: { subscriptions: IInstructorSubscription[]; setSubsToShow: (subsToShow: IInstructorSubscription[]) => void }) => {
    const colors = useColors();
    const subsIds = subscriptions.map(sub => String(sub.id));

    const dailySubs = useDailySubs(subsIds.join("-"), !!subscriptions?.length);

    const allAvailableDates = dailySubs?.data ? dailySubs.data : [];

    const subsColors = useMemo(() => subsIds.reduce((acc, id) => {
        // acc.push({ subId: String(id), color: generateDistinctLightColor(acc.map(v => v.color)) });
        acc.push({ subId: String(id), color: colors.accent });
        return acc;
    }, [] as { subId: string, color: string }[]), [subsIds])

    const markedDates = useMemo(() => allAvailableDates?.reduce((acc, val) => {
        let markedDates = { [val.date]: { disabled: false, marked: false, selected: true, selectedColor: subsColors.find((sub) => sub.subId === String(val.subscriptionId))?.color || colors.accent2, selectedTextColor: colors.white } } as MarkedDates;

        if(!markedDates[format(new Date(), dateFormat)]) markedDates = {...markedDates, [format(new Date(), dateFormat)]: { disabled: true } }
        return { ...acc, ...markedDates }
    }, {}), [allAvailableDates, subsColors]);

    const chooseDate = (date: string) => {
        const activeSubs = subscriptions.filter(sub => {
            return parse(sub.dateFrom, dateFormat, new Date()) <= parse(date, dateFormat, new Date()) && (parse(date, dateFormat, new Date()) <= parse(sub.dateTo, dateFormat, new Date()));
        });
        setSubsToShow(activeSubs);
    }

    return (
        <>
            <Calendar
                theme={{
                    calendarBackground: colors.transparent,
                    dayTextColor: colors.accent,
                    textDisabledColor: colors.darkGrey,
                    // todayBackgroundColor: colors.transparent,
                    todayTextColor: colors.darkGrey,
                    arrowColor: colors.accent,
                    monthTextColor: colors.accent,
                    textMonthFontWeight: 'bold',
                }}
                renderArrow={(direction) => direction === 'left' ?
                    <Icon name={'arrow-back-outline'} color={'accent'} /> :
                    <Icon name={'arrow-forward-outline'} color={'accent'} />}
                onDayPress={(e) => chooseDate(e.dateString)}
                pastScrollRange={0}
                futureScrollRange={12}
                markedDates={markedDates}
                disabledByDefault
                enableSwipeMonths={false}
                scrollEnabled={false}
                animateScroll={false}
                disableAllTouchEventsForDisabledDays
                hideExtraDays
                calendarHeight={100}
            />
        </>
    )}, (prevProps, nextProps) => isEqual(prevProps.subscriptions, nextProps.subscriptions ) )

export default ScheduleContainer;