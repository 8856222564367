import React from "react";
import {Input} from "../../../components/EditableInput";
import {Logo} from "../../../components/Icons/Icons";
import {Button, View} from "../../../components/Themed";
import {S} from "../../../constants/Styles";
import {sanitizeEmail, showToast, validateEmail} from "../../../helpers/helpers";
import {useColors} from "../../../hooks/ThemeContext";
import {Image, View as DView} from 'react-native';
import {api} from "../../../api/api";
// @ts-ignore
import img from '../../../../assets/images/main.jpg';
import {WebWrapper} from "../../../components/WebWrapper";

const ForgotPasswordScreen = () => {
    const [email, setEmail] = React.useState("");
    const colors = useColors();

    const handleEmail = (email: string) => {
        setEmail(sanitizeEmail(email));
    };

    const reset = () => {
        if (!validateEmail(email)) {
            showToast("Email is invalid");
            return;
        }
        return api.post('auth/forgot-password', {email: email.trim().toLowerCase()}).then(() => {
            showToast("You'll receive an email to reset your password")
        }).catch(console.log)
    };

    return (
        <View style={[S.flex]} bg={"accent"}>
            <Image style={[S.wFull, S.hFull, {position: 'absolute', top: 0, right: 0, left: 0}]}
                   source={{uri: img}}/>
            <WebWrapper>
                <DView style={[S.flex, S.justify]}>
                    <DView style={[S.flex, S.end, S.center, S.mb3]}>
                        <Logo size={200}/>
                    </DView>
                    <DView style={[S.flex]}>
                        <Input placeholder="Enter your email" onChangeText={handleEmail} value={email}
                               containerStyle={S.mb3}
                               onSubmitEditing={reset}/>
                        <Button buttonStyle={{backgroundColor: colors.accent}} titleStyle={{color: colors.white}}
                                type="outline"
                                containerStyle={S.mb1} title={"Reset password"} onPress={() => reset()}/>
                    </DView>
                </DView>
            </WebWrapper>
        </View>
    );
};

export default ForgotPasswordScreen;
