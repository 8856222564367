import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {showToast} from "../../helpers/helpers";
import {Button, Text, View} from "../Themed";
import {S} from '../../constants/Styles';
import {useColors} from "../../hooks/ThemeContext";
import {BASE_URL} from "../../api/api";
import {navigate} from "../../navigation/helpers";

const PaymentCheckoutForm = ({
                                 amount,
                                 clientSecret,
                                 resetPayments
                             }: { amount: string, clientSecret: string, resetPayments: () => void }) => {
    const stripe = useStripe();
    const elements = useElements();
    const colors = useColors();
    const handleSubmit = async () => {

        if (!stripe || !elements) {
            return;
        }

        return stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${BASE_URL}Subscriptions`,
            },
            redirect: 'if_required'
        }).then((result) => {
            if (result.error) {
                console.log(result?.error?.message);
                if (result?.error?.message)
                    showToast(result.error.message, "LONG");
            } else {
                resetPayments();
                showToast('Payment Successful', "LONG");
                navigate('Subscriptions');
            }
        }).catch(err => {
            showToast("Payment failed", "LONG");
        });
    };

    return (
        <>
            <PaymentElement options={{terms: {card: 'always'}}}/>
            <Text variant={"SubText"} color={'lightGrey'} style={{textAlign: "center"}}>Payments are powered by
                Stripe</Text>
            <View style={S.mt3}>
                <Button buttonStyle={{backgroundColor: stripe ? colors.accent : colors.lightGrey}}
                        disabled={!stripe || !elements} onPress={handleSubmit}>Pay {amount}</Button>
            </View>
        </>
    )
};

export default PaymentCheckoutForm;
