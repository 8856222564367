// @flow
import * as React from 'react';
import {ISubscription} from "../../api/types";
import {PaymentsBase} from "./PaymentsBase.web";
import {CustomModal} from "../CustomModal";

export function PaymentsModal({
                                  subscription,
                                  onClose
                              }: { subscription: ISubscription | null, onClose: () => void }) {
    if (!subscription) {
        onClose();
        return null;
    }
    return (
        <CustomModal body={<PaymentsBase subscription={subscription} resetPayments={onClose}/>} goBack={onClose}/>
    );
}
